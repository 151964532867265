import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Typography, Button, Select, MenuItem } from "@mui/material";
import Swal from 'sweetalert2';

// MUI ICONS
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// API
import { api } from './../../api/reservation';

const ChooseService = ({ setStepCount, selectedService, setSelectedService, setSelectedServiceName }) => {
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const [listServices, setListServices] = useState([]);

    useEffect(() => {
        reloadService();
    }, []);

    const handleEnd = () => {
        navigate('/');
    }

    const handleNextStep = () => {
        if(selectedService <= 0)
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'กรุณาเลือกบริการก่อนค่ะ',
                confirmButtonText: 'ตกลง'
            });
        }

        setStepCount(1);
    }

    const reloadService = async () => {
        try {
            const service = new api();
            const getListServices = await service.getListServices();
            
            if(getListServices.data.status_code === 200)
            {
                setListServices(getListServices.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleChangeService = (e) => {
        const findIndexService = listServices.findIndex((element) => element.id === parseInt(e.target.value));

        if(findIndexService === -1)
        {
            setSelectedService(null);
            setSelectedServiceName(null);
            return;
        }
        
        setSelectedService(parseInt(e.target.value));
        setSelectedServiceName(listServices[findIndexService].name)
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography mt={2} mb={1} fontSize={14} color={'black'}>
                    บริการ
                </Typography>
                <Select
                    fullWidth
                    size="small"
                    onChange={handleChangeService}
                    defaultValue={`0`}
                >
                    <MenuItem value={`0`}>กรุณาเลือกบริการ</MenuItem>
                    {
                        listServices.map((item, index) => {
                            return (
                                <MenuItem value={`${item.id}`} key={index}>{item.name}</MenuItem>
                            )
                        })
                    }
                </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button fullWidth onClick={handleNextStep} disabled={processing} sx={
                    {
                        backgroundColor: `#d3c5b8`,
                        color: '#4b3320',
                        '&:hover': {
                            backgroundColor: `#a0958b`,
                            color: '#4b3320',
                        }
                    }
                }>
                    ถัดไป <NavigateNextIcon />
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button fullWidth onClick={handleEnd} sx={
                    {
                        backgroundColor: `#d3c5b8`,
                        color: '#4b3320',
                        '&:hover': {
                            backgroundColor: `#a0958b`,
                            color: '#4b3320',
                        }
                    }
                }>
                    <ChevronLeftIcon /> กลับหน้าหลัก
                </Button>
            </Grid>
        </Grid>
    )
}

export default ChooseService;