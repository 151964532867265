import { Container, Box } from "@mui/material";
import { employee } from "../../../api/employee";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

import Navbar from "../Navbar/Navbar";
import MembersTable from "../Member/MembersTable";
import PageLoading from "./../../Loading/PageLoading";

const Member = ({ loaded }) => {
    const cookies = new Cookies(null, { path: '/' });
    const [members, setMembers] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const service = new employee();
                const getMembers = await service.getMembers(cookies.get('access_token'));

                setMembers(getMembers.data.members);
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    return (
        loaded ? <>
            <Navbar loaded={loaded} />
            <Container sx={{ pt: 12, mb: 3 }}>
                <Box width={`100%`} bgcolor={`white`} borderRadius={3} sx={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.2)" }}>
                    <Box p={3}>
                        <MembersTable members={members} />
                    </Box>
                </Box>
            </Container>
        </> : <PageLoading />
    )
}

export default Member;