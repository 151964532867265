import { useEffect, useState } from "react";
import { employee } from "../../../api/employee";
import { api } from "../../../api/reservation";
import Cookies from "universal-cookie";

import { Container, Box, Grid } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import PageLoading from "../../Loading/PageLoading";

import ManageListService from "./ManageListService";

const ManageDayAvaliable = ({ loaded }) => {
    const cookies = new Cookies(null, { path: '/' });

    const [avaliableTime, setAvaliableTime] = useState([]);
    const [listServices, setListServices] = useState([]);

    useEffect(() => {
        reloadAvaliableTime();
        reloadService();
    }, []);

    const reloadAvaliableTime = async () => {
        try {
            const token = cookies.get('access_token');
            const service = new employee();
            const getAvaliableTime = await service.getAvaliableTime(token);

            if(getAvaliableTime.data.status_code === 200)
            {
                setAvaliableTime(getAvaliableTime.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const reloadService = async () => {
        try {
            const service = new api();
            const getListServices = await service.getListServices();
            
            if(getListServices.data.status_code === 200)
            {
                setListServices(getListServices.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        loaded ? <>
            <Navbar loaded={loaded} />
            <Container sx={{ pt: 12, mb: 3 }}>
                <Box width={`100%`} bgcolor={`white`} borderRadius={3} sx={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.2)" }}>
                    <Box p={3}>
                        {
                            listServices.length > 0 ? <Grid container spacing={2}>
                                {
                                    listServices.map((item, index) => <ManageListService item={item} avaliableTime={avaliableTime} reloadAvaliableTime={reloadAvaliableTime} setAvaliableTime={setAvaliableTime} key={`manage-service-avaliable-time-${index}`} />)
                                }
                            </Grid> : <PageLoading />
                        }
                    </Box>
                </Box>
            </Container>
        </> : <PageLoading />
    )
}

export default ManageDayAvaliable;