import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Typography, Button } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

// MUI ICONS
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

// API
import { api } from './../../api/reservation';

const ChooseDate = ({ selectedDate, setSelectedDate, setStepCount }) => {
    const navigate = useNavigate();
    const dateRef = useRef(null);
    const [processing, setProcessing] = useState(false);

    const handleBackStep = () => {
        setStepCount(0);
    }

    const handleNextStep = () => {
        if(dateRef.current.value === '' || dateRef.current.value === undefined)
        {
            return;
        }

        (async () => {
            try {
                setProcessing(true);
                const service = new api();
                const checkDate = await service.checkDateAvaliable(dateRef.current.value);

                if(checkDate.data.status_code === 200)
                {
                    setSelectedDate(dateRef.current.value);
                    setStepCount(2);
                }
                else
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'เกิดข้อผิดพลาด',
                        text: checkDate.data.message || 'Oops..',
                        confirmButtonText: "ตกลง",
                    });
                }
                
                setProcessing(false);
            } catch (err) {
                console.log(err)
            }
        })()
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography mt={2} mb={1} fontSize={14} color={'black'}>
                    วัน/เดือน/ปี
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        slots={{
                            openPickerIcon: CalendarMonthRoundedIcon,
                        }}
                        slotProps={{
                            openPickerIcon: { fontSize: 'large' },
                            openPickerButton: { color: 'black' },
                            textField: {
                                focused: false,
                                color: 'secondary',
                            },
                        }}
                        sx={
                            {
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: 1,
                                },
                                '& .MuiIconButton-root': {
                                    backgroundColor: '#fff',
                                    color: '#d3c5b8',
                                    borderRadius: 3
                                },
                                '& .MuiIconButton-root:hover': {
                                    backgroundColor: '#fff',
                                    color: '#a0958b',
                                    borderRadius: 3
                                },
                            }
                        }
                        inputFormat="dd.MM.yyyy"
                        format="D/MM/YYYY"
                        inputRef={dateRef}
                        disablePast
                        defaultValue={selectedDate !== null ? dayjs(new Date(`${selectedDate.split('/')[2]}-${selectedDate.split('/')[1]}-${selectedDate.split('/')[0]}`)) : null}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button fullWidth onClick={handleNextStep} disabled={processing} sx={
                    {
                        backgroundColor: `#d3c5b8`,
                        color: '#4b3320',
                        '&:hover': {
                            backgroundColor: `#a0958b`,
                            color: '#4b3320',
                        }
                    }
                }>
                    ถัดไป <NavigateNextIcon />
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button fullWidth onClick={handleBackStep} sx={
                    {
                        backgroundColor: `#d3c5b8`,
                        color: '#4b3320',
                        '&:hover': {
                            backgroundColor: `#a0958b`,
                            color: '#4b3320',
                        }
                    }
                }>
                    <ChevronLeftIcon /> ย้อนกลับ
                </Button>
            </Grid>
        </Grid>
    )
}

export default ChooseDate;