import { TableRow, TableCell, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

const DayData = ({ data, deleteBlockedDate }) => {
    return (
        <TableRow>
            <TableCell align="center">
                {
                    `${(String(new Date(data.date).getDate())).length > 1 ? new Date(data.date).getDate() : `0${new Date(data.date).getDate()}`}/${(String(new Date(data.date).getMonth() + 1)).length > 1 ? new Date(data.date).getMonth() + 1 : `0${new Date(data.date).getMonth() + 1}`}/${new Date(data.date).getFullYear()}`
                }
            </TableCell>
            <TableCell>
                <IconButton onClick={() => deleteBlockedDate(data.id)} sx={
                    {
                        backgroundColor: '#f20000',
                        m: .5,
                        borderRadius: 3,
                        '&:hover': {
                            backgroundColor: '#bf0000'
                        }
                    }
                }>
                    <DeleteIcon fontSize='small' sx={{ color: 'white' }} />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default DayData;