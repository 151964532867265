import { CSVLink } from "react-csv";
import { useEffect, useState } from "react";

import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, IconButton, Paper, Typography } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

const ExportToCsv = ({ data }) => {
    const [downloadCsvData, setDownloadCsvData] = useState([]);
    const [processing, setProcessing] = useState(true);

    useEffect(() => {
        setCsvData();
    }, []);

    const setCsvData = async () => {
        if(!data || !data.length > 0)
        {
            return;
        }

        const csvData = [
            ["ชื่อผู้จอง", "เบอร์โทรศัพท์", "วันเวลา", "บริการ", "ราคา ค่าบริการ", "พนักงาน"]
        ];

        await Promise.all(data.sort((a,b) => new Date(b.date) - new Date(a.date)).map((element) => {
            const date = new Date(element.date);
            const reservation_date = `${(String(date.getDate())).length > 1 ? date.getDate() : `0${date.getDate()}`}/${(String(date.getMonth() + 1)).length > 1 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}/${date.getFullYear()}`;
            const timeString = element.time;
            const timeParts = timeString.split(":");
            const formattedTime = `${timeParts[0]}:${timeParts[1]}`;
            csvData.push([`${element.name}`,`${element.phone}`,`${reservation_date} ${formattedTime}`,`${element.service_name}`,`${element.service_price}`,`${element.employee_name === null ? 'ไม่มีพนักงาน' : element.employee_name}`]);
        }))

        setDownloadCsvData(csvData);
        setProcessing(false);
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={2}>
                            <Typography fontSize={22}>
                                DOWNLOAD ไฟล์
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center">
                            ชื่อไฟล์
                        </TableCell>
                        <TableCell align="center">
                            #
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableCell align="center">
                        ตารางการจองทั้งหมด
                    </TableCell>
                    <TableCell align="center">
                        {
                            !processing && <CSVLink data={downloadCsvData} filename={"ตารางการจองทั้งหมด.csv"}>
                                <IconButton size="small" sx={
                                    {
                                        backgroundColor: '#d3c5b8',
                                        color: '#4b3320',
                                        borderRadius: 2,
                                        '&:hover': {
                                            backgroundColor: '#a0958b',
                                            color: '#4b3320',
                                        }
                                    }
                                }>
                                    <DownloadIcon fontSize="small" />
                                </IconButton>
                            </CSVLink>
                        }
                    </TableCell>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ExportToCsv;