import { useState } from "react";
import { api } from "../../api/reservation";
import Swal from "sweetalert2";
import { Typography, Grid, Button, Divider } from "@mui/material";

// MUI ICONS
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const Confirm = ({ nameInput, phoneInput, selectedTime, selectedDate, setStepCount, setSelectedDate, setSelectedTime, profile, selectedService, selectedServiceName }) => {
    const [processing, setProcessing] = useState(false);

    const handleNextStep = async () => {
        try {
            setProcessing(true);
            const service = new api();
            const checkDateAvaliable = await service.checkDateAvaliable(selectedDate);

            if(checkDateAvaliable.data.status_code !== 200)
            {
                setSelectedDate(null);
                return Swal.fire({
                    icon: 'error',
                    title: 'เกิดข้อผิดพลาด',
                    text: `ไม่สามารถเลือกวันที่ ${selectedDate} ได้ในขณะนี้ กรุณาเลือกใหม่อีกครั้งค่ะ`,
                    confirmButtonText: 'ตกลง'
                }).then(() => {
                    setStepCount(0);
                });
            }

            const checkTimeAvaliable = await service.checkTimeAvaliable(selectedDate, selectedTime, selectedService, profile.userId);
            if(!checkTimeAvaliable.data.result)
            {
                setSelectedTime(null);
                return Swal.fire({
                    icon: 'error',
                    title: 'เกิดข้อผิดพลาด',
                    text: `ขออภัยค่ะ ไม่สามารถเลือกเวลา ${selectedTime} ได้ในขณะนี้ เนื่องจากคุณจองวันและเวลานี้ไว้แล้ว หรือ วันและเวลานี้ไม่ว่างแล้ว กรุณาเลือกใหม่อีกครั้งค่ะ`,
                    confirmButtonText: 'ตกลง'
                }).then(() => {
                    setStepCount(1);
                });
            }

            const confirmReservation = await service.confirmReservation(selectedDate, selectedTime, nameInput, phoneInput, selectedService, profile);
            if(confirmReservation.data.status_code === 200 && confirmReservation.data.result)
            {
                return setStepCount(5);
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'เกิดข้อผิดพลาด',
                    text: 'เกิดข้อผิดพลาดไม่ทราบสาเหตุ',
                    confirmButtonText: 'ตกลง'
                });
            }

            setProcessing(false);
        } catch (err) {
            console.log(err);
        }
    }

    const handleBackStep = () => {
        setStepCount(3);
    }

    return (
        <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography fontSize={24}>
                    ชื่อผู้จอง: { nameInput }
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography fontSize={16}>
                    เบอร์โทรศัพท์: { phoneInput }
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography fontSize={16}>
                    บริการ: { selectedServiceName }
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography fontSize={16}>
                    วันที่จอง: { selectedDate }
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography fontSize={16}>
                    เวลาที่จอง: { selectedTime }
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider sx={{ mb: 1 }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button fullWidth onClick={handleNextStep} disabled={processing} sx={
                    {
                        backgroundColor: `#d3c5b8`,
                        color: '#4b3320',
                        '&:hover': {
                            backgroundColor: `#a0958b`,
                            color: '#4b3320',
                        },
                        '&:disabled': {
                            color: 'rgba(255, 255, 255, .5)',
                        }
                    }
                }>
                    ถัดไป <NavigateNextIcon />
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button fullWidth onClick={handleBackStep} sx={
                    {
                        backgroundColor: `#d3c5b8`,
                        color: '#4b3320',
                        '&:hover': {
                            backgroundColor: `#a0958b`,
                            color: '#4b3320',
                        }
                    }
                }>
                    <ChevronLeftIcon /> ย้อนกลับ
                </Button>
            </Grid>
        </Grid>
    )
}

export default Confirm;