import { Box } from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress';

const PageLoading = () => {
    return (
        <Box display={`flex`} justifyContent={`center`} py={3}>
            <CircularProgress sx={{ color: '#d3c5b8' }} />
        </Box>
    )
}

export default PageLoading