import { useEffect, useState } from "react";
import { api } from "../../../api/reservation";
import { Container, Box, Grid, Typography, Divider } from "@mui/material";

import Navbar from "../Navbar/Navbar";
import PageLoading from "../../Loading/PageLoading";
import TableEmployeesList from "./TableEmployeesList";

const EmployeeList = ({ loaded, employees, reloadEmployees }) => {
    const [listServices, setListServices] = useState([]);

    useEffect(() => {
        reloadService();
    }, []);

    const reloadService = async () => {
        try {
            const service = new api();
            const getListServices = await service.getListServices();
            
            if(getListServices.data.status_code === 200)
            {
                setListServices(getListServices.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        loaded ? <>
            <Navbar loaded={loaded} />
            <Container sx={{ pt: 12, mb: 3 }}>
                <Box width={`100%`} bgcolor={`white`} borderRadius={3} sx={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.2)" }}>
                    <Box p={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box height={`100%`}>
                                    <Typography fontSize={18} display={`flex`} alignItems={`center`} height={`100%`}>
                                        พนักงานในระบบ มีทั้งหมด { parseFloat(employees.length).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) } คน
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TableEmployeesList data={employees} reloadEmployees={reloadEmployees} listServices={listServices} />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </> : <PageLoading />
    )
}

export default EmployeeList;