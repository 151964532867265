import { useState, useRef } from "react";
import { employee } from "../../../api/employee";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

import { TableRow, TableCell, Box, FormControl, FormLabel, OutlinedInput, Button, Grid, Collapse, Typography } from "@mui/material";

const AddEmployee = () => {
    const cookies = new Cookies(null, { path: '/' });
    const [open, setOpen] = useState(false);
    const usernameRef = useRef(null);
    const nameRef = useRef(null);
    const passwordRef = useRef(null);

    const handleAddEmployee = async () => {
        const username = usernameRef.current.value;
        const name = nameRef.current.value;
        const password = passwordRef.current.value;

        if(username === undefined || username === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'จำเป็นต้องกรอก ชื่อผู้ใช้งาน',
                confirmButtonText: 'ตกลง'
            });
        }
        else if(username.length < 4)
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'ชื่อผู้ใช้งาน จำเป็นต้องมีมากกว่าหรือเท่ากับ 4 ตัวอักษร',
                confirmButtonText: 'ตกลง'
            });
        }
        else if(name === undefined || name === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'จำเป็นต้องกรอก ชื่อพนักงาน',
                confirmButtonText: 'ตกลง'
            });
        }
        else if(password === undefined || password === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'จำเป็นต้องกรอก รหัสผ่าน',
                confirmButtonText: 'ตกลง'
            });
        }
        else if(password.length < 6)
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'รหัสผ่านจำเป็นต้องมีมากกว่าหรือเท่ากับ 6 ตัวอักษร',
                confirmButtonText: 'ตกลง'
            });
        }

        try {
            const token = cookies.get('access_token');
            const service = new employee();
            const registerService = await service.register(token, username, password, name);

            if(registerService.data.status_code === 200)
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: 'เพิ่มพนักงานใหม่เรียบร้อยแล้วค่ะ',
                    confirmButtonText: 'ตกลง'
                });
                return window.location.reload();
            }

            Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: registerService.data.message || "พบข้อผิดพลาด ไม่ทราบสาเหตุ",
                confirmButtonText: 'ตกลง'
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="center" colSpan={3}>
                    <Button fullWidth onClick={() => setOpen(!open)} sx={
                        {
                            backgroundColor: `#d3c5b8`,
                            color: '#4b3320',
                            '&:hover': {
                                backgroundColor: `#a0958b`,
                                color: '#4b3320',
                            }
                        }
                    }>
                        เพิ่มพนักงาน
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        เพิ่มพนักงาน
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                        <FormLabel htmlFor={`add_employee_username`}>
                                            ชื่อผู้ใช้งาน (ภาษาอังกฤษเท่านั้น)
                                        </FormLabel>
                                        <OutlinedInput
                                            name={`add_employee_username`}
                                            type={`text`}
                                            size='small'
                                            fullWidth
                                            autoComplete={`add_employee_username`}
                                            inputRef={usernameRef}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                        <FormLabel htmlFor={`add_employee_password`}>
                                            รหัสผ่าน
                                        </FormLabel>
                                        <OutlinedInput
                                            name={`add_employee_password`}
                                            type={`password`}
                                            size='small'
                                            fullWidth
                                            autoComplete={`add_employee_password`}
                                            inputRef={passwordRef}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <FormControl fullWidth>
                                        <FormLabel htmlFor={`add_employee_name`}>
                                            ชื่อพนักงาน
                                        </FormLabel>
                                        <OutlinedInput
                                            name={`add_employee_name`}
                                            type={`text`}
                                            size='small'
                                            fullWidth
                                            autoComplete={`add_employee_name`}
                                            inputRef={nameRef}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullWidth onClick={handleAddEmployee} sx={
                                        {
                                            backgroundColor: `#d3c5b8`,
                                            color: '#4b3320',
                                            '&:hover': {
                                                backgroundColor: `#a0958b`,
                                                color: '#4b3320',
                                            }
                                        }
                                    }>
                                        เพิ่ม
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default AddEmployee;