import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

const Logout = () => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, { path: '/' });

    useEffect(() => {
        cookies.remove('access_token');
        navigate('/backoffice');
    }, [])

    return "กำลังออกจากระบบให้คุณ"
}

export default Logout;