import axios from "axios";

export class employee {
    constructor() {
        this.baseURL = process.env.REACT_APP_API_URL;
        this.response = {};
        this.instance = axios.create({
            baseURL: this.baseURL,
            timeout: 30000,
            timeoutErrorMessage: "Time out!",
        });
    }

    getBaseURL = () => {
        return this.baseURL;
    }
    
    get = async (path, config) => {
        return this.instance.get(path, config).then((res) => {
            return res;
        });
    }

    post = async (path, body, config) => {
        return this.instance.post(path, body, config).then((res) => {
            return res;
        });
    }

    login = async (username, password) => {
        return this.instance.post('/employee/login', {
            username,
            password
        }, {}).then((res) => {
            return res;
        });
    }

    register = async (token, username, password, name) => {
        return this.instance.post('/employee/register', {
            username,
            password,
            name
        }, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    countMembers = async (token) => {
        return this.instance.get('/employee/get/countMembers', {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    getMembers = async (token) => {
        return this.instance.get('/employee/get/members', {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    editMemberPoints = async (token, line_uuid, points) => {
        return this.instance.post('/employee/edit/points', {
            line_uuid,
            points
        }, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    giveMemberPoints = async (token, line_uuid, points, reason) => {
        return this.instance.post('/employee/add/points', {
            line_uuid,
            points,
            reason
        }, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    getEmployees = async (token) => {
        return this.instance.get('/employee/get/employees', {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    deleteEmployee = async (token, employee_id) => {
        return this.instance.post('/employee/delete/employee', {
            employee_id
        }, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    getAvaliableTime = async (token) => {
        return this.instance.get('/employee/get/avaliabletime', {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    updateAvaliableTime = async (token, data) => {
        return this.instance.post('/employee/update/avaliabletime', {
            data: data
        }, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    updateAvaliableTimeData = async (token, id, data) => {
        return this.instance.post(`/employee/update/avaliabletimedata`, {
            id: id,
            data: data
        }, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    getBlockedDate = async (token) => {
        return this.instance.get(`/employee/get/blockeddate`, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    deleteBlockedDate = async (token, id) => {
        return this.instance.post(`/employee/delete/blockeddate`, {
            id
        }, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    addBlockedDate = async (token, dateString) => {
        return this.instance.post(`/employee/add/blockeddate`, {
            date: dateString
        }, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    deleteService = async (token, service_id) => {
        return this.instance.post(`/employee/delete/service`, {
            service_id
        }, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    editService = async (token, service_name, service_price, service_id) => {
        return this.instance.post(`/employee/edit/service`, {
            service_name,
            service_price,
            service_id
        }, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    addService = async (token, service_name, service_price) => {
        return this.instance.post(`/employee/add/service`, {
            service_name,
            service_price
        }, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    editEmployee = async (token, employee_id, employee_name, employee_service) => {
        return this.instance.post(`/employee/edit/employee`, {
            employee_id,
            employee_name,
            employee_service
        }, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    getReservation = async (token) => {
        return this.instance.get(`/employee/get/reservation`, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    updateReservationServiceId = async (token, reservation_id, service_id) => {
        return this.instance.post(`/employee/update/reservationservice`, {
            reservation_id,
            service_id
        }, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }

    updateReservationEmployeeId = async (token, reservation_id, employee_id) => {
        return this.instance.post(`/employee/update/reservationemployee`, {
            reservation_id,
            employee_id
        }, {
            headers: {
                Authorization: token
            }
        }).then((res) => {
            return res;
        });
    }
}