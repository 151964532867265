import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { employee } from "../../api/employee";
import Cookies from 'universal-cookie';

import Home from "./Home/Home";
import Login from "./Login/Login";
import Member from "./Member/Member";
import EditMember from "./Member/EditMember";
import EmployeeList from "./ManageEmployee/EmployeeList";
import ManageDayAvaliable from "./ManageDayAvaliable/ManageDayAvaliable";
import ManageDayBlocked from "./ManageDayBlocked/ManageDayBlocked";
import ManageService from "./ManageService/ManageService";
import Logout from "./Logout/Logout";
import PageLoading from "../Loading/PageLoading";

const AppBackOffice = ({ loaded }) => {
    const cookies = new Cookies(null, { path: '/' });
    const { page, line_uuid } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [members, setMembers] = useState([]);
    const [employees, setEmployees] = useState([]);
    // const [isLogged, setIsLogged] = useState(false);

    useEffect(() => {
        const token = cookies.get('access_token');
        
        if(token === undefined)
        {
            if(page !== 'login')
            {
                return navigate('/backoffice/login');
            }
            else
            {
                return;
            }
        }

        reloadMembers();
        reloadEmployees();
        setLoading(false);
    }, [cookies.get('access_token')]);

    const reloadMembers = async () => {
        const token = cookies.get('access_token');
        
        try {
            const service = new employee();
            const getMembers = await service.getMembers(token);

            setMembers(getMembers.data.members);
        } catch (err) {
            console.log(err);
        }
    }

    const reloadEmployees = async () => {
        const token = cookies.get('access_token');
        
        try {
            const service = new employee();
            const getEmployees = await service.getEmployees(token);
            
            setEmployees(getEmployees.data.employees);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            {
                (page === 'member' && line_uuid !== undefined) ? (loading ? <PageLoading /> : <EditMember members={members} loaded={loaded} reloadMembers={reloadMembers} />) : <>
                    {
                        page === undefined && <Home loaded={loaded} employees={employees} />
                    }
                    {
                        page === 'members' && <Member loaded={loaded} />
                    }
                    {
                        page === 'login' && <Login navigate={navigate} />
                    }
                    {
                        page === 'employee' && <EmployeeList loaded={loaded} employees={employees} reloadEmployees={reloadEmployees} />
                    }
                    {
                        page === 'managedayavaliable' && <ManageDayAvaliable loaded={loaded} />
                    }
                    {
                        page === 'managedayblocked' && <ManageDayBlocked loaded={loaded} />
                    }
                    {
                        page === 'manageservices' && <ManageService loaded={loaded} />
                    }
                    {
                        page === 'logout' && <Logout />
                    }
                </>
            }
        </>
    );
}

export default AppBackOffice;