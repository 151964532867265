import { Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

// MUI ICONS
import CheckIcon from '@mui/icons-material/Check';

const Success = () => {
    const navigate = useNavigate();
    const handleEnd = () => {
        navigate('/');
    }

    return (
        <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography fontSize={24} fontWeight={`bold`} display={`flex`} justifyContent={`center`}>
                    สถานะ: จองเรียบร้อยแล้ว
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button fullWidth onClick={handleEnd} sx={
                    {
                        backgroundColor: `#d3c5b8`,
                        color: '#4b3320',
                        '&:hover': {
                            backgroundColor: `#a0958b`,
                            color: '#4b3320',
                        }
                    }
                }>
                    <CheckIcon /> เสร็จสิ้น
                </Button>
            </Grid>
        </Grid>
    )
}

export default Success;