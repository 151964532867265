import { Typography } from "@mui/material"

const Footer = () => {
    return (
        <>
            <Typography display={`flex`} justifyContent={`center`} fontSize={12} color={`rgba(0,0,0,.5)`}>
                &copy;{
                    String('Sabaidee Health Massage').toLocaleUpperCase()
                }
            </Typography>
            <Typography display={`flex`} justifyContent={`center`} fontSize={12} color={`rgba(0,0,0,.5)`}>
                APPLICATION LINE OA VERSION: 1.0
            </Typography>
        </>
    )
}

export default Footer