import { Container, Grid, Box, Typography, Divider, FormControl, FormLabel, OutlinedInput, Button } from "@mui/material";
import { useRef, useEffect, useState } from "react";
import { employee } from "../../../api/employee";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

// MUI ICONS
import LoginIcon from '@mui/icons-material/Login';

const Login = ({ navigate }) => {
    const cookies = new Cookies(null, { path: '/' });

    const [loading, setLoading] = useState(true);
    const usernameInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    
    useEffect(() => {
        if(cookies.get('access_token') !== undefined)
        {
            return navigate('/backoffice');
        }

        setLoading(false);
    }, []);

    const handleLogin = async () => {
        const username = usernameInputRef.current.value;
        const password = passwordInputRef.current.value;

        try {
            const service = new employee();
            const registerResult = await service.login(username, password);

            if(registerResult.data.status_code === 200)
            {
                cookies.set('access_token', registerResult.data.data.access_token);
                return Swal.fire({
                    icon: "success",
                    title: "แจ้งเตือน",
                    text: "เข้าสู่ระบบสำเร็จ",
                    confirmButtonText: "ตกลง"
                }).then(() => {
                    navigate('/backoffice');
                });
            }
            else
            {
                return Swal.fire({
                    icon: "error",
                    title: "เกิดข้อผิดพลาด",
                    text: registerResult.data.message || "ไม่ทราบสาเหตุ",
                    confirmButtonText: "ตกลง"
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Container>
            {
                !loading && <Grid container sx={{ my: 3 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box width={`100%`} bgcolor={`white`} borderRadius={3} sx={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.2)" }}>
                            <Box p={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography display={`flex`} justifyContent={`center`}>
                                            เข้าสู่ระบบ (พนักงาน)
                                        </Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl fullWidth>
                                            <FormLabel htmlFor="reservation_employee_username">
                                                ชื่อผู้ใช้งาน
                                            </FormLabel>
                                            <OutlinedInput
                                                name='reservation_employee_username'
                                                type={`text`}
                                                size='small'
                                                fullWidth
                                                autoComplete="reservation_employee_username"
                                                placeholder="Username"
                                                inputRef={usernameInputRef}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl fullWidth>
                                            <FormLabel htmlFor="reservation_employee_password">
                                                รหัสผ่าน
                                            </FormLabel>
                                            <OutlinedInput
                                                name='reservation_employee_password'
                                                type={`password`}
                                                size='small'
                                                fullWidth
                                                autoComplete="reservation_employee_password"
                                                placeholder="•••••••••••••"
                                                inputRef={passwordInputRef}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Button fullWidth onClick={handleLogin} sx={
                                            {
                                                backgroundColor: `#d3c5b8`,
                                                color: '#4b3320',
                                                '&:hover': {
                                                    backgroundColor: `#a0958b`,
                                                    color: '#4b3320',
                                                },
                                                '&:disabled': {
                                                    color: 'rgba(255, 255, 255, .5)',
                                                }
                                            }
                                        }>
                                            <LoginIcon /> เข้าสู่ระบบ
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid> 
                </Grid>
            }
        </Container>
    )
}

export default Login;