import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import liff from '@line/liff';
import { api } from "./api/reservation";

// COMPONENTS
import Home from "./components/Home/Home";
import Reservation from "./components/Reservation/Reservation";
import HistoryReservation from "./components/Reservation/HistoryReservation";
import HistoryPoints from "./components/HistoryPoints/HistoryPoints";
import AppBackOffice from "./components/BackOffice/AppBackOffice";
import Footer from "./components/Footer/Footer";
// import NavbarComponent from "./components/Navbar/NavbarComponent";

// CSS
// import './assets/css/app.css';

const App = () => {
    const [loaded, setLoaded] = useState(false);
    const [profile, setProfile] = useState({});
    const [memberData, setMemberData] = useState({});

    useEffect(() => {
        (async () => {
            try {
                await liff.init({
                    liffId: process.env.REACT_APP_LINE_LIFF_ID, // Use own liffId
                });
                
                if(!liff.isLoggedIn())
                {
                    return liff.login();
                }
                
                const tmp_profile = await liff.getProfile();
                const service = new api();
                const member_detail = await service.getMemberDetail(tmp_profile.userId, tmp_profile.displayName, tmp_profile.pictureUrl);
                
                setMemberData(member_detail.data);
                setProfile(tmp_profile);
                setLoaded(true);
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    const logout = () => {
        liff.logout();
    }

    return (
        <>
            <Router>
                {/* <NavbarComponent loaded={loaded} profile={profile} logout={logout} /> */}
                <div className='App'>
                    <Routes>
                        <Route path="/" exact element={
                            <Home loaded={loaded} logout={logout} profile={profile} memberData={memberData} />
                        } />
                        <Route path="/reservation" exact element={
                            <Reservation loaded={loaded} logout={logout} profile={profile} memberData={memberData} />
                        } />
                        <Route path="/history-reservation" exact element={
                            <HistoryReservation loaded={loaded} logout={logout} profile={profile} memberData={memberData} />
                        } />
                        <Route path="/history-points" exact element={
                            <HistoryPoints loaded={loaded} logout={logout} profile={profile} memberData={memberData} />
                        } />
                        <Route path="/backoffice" element={
                            <AppBackOffice loaded={loaded} logout={logout} profile={profile} memberData={memberData} />
                        } />
                        <Route path="/backoffice/:page" element={
                            <AppBackOffice loaded={loaded} logout={logout} profile={profile} memberData={memberData} />
                        } />
                        <Route path="/backoffice/:page/:line_uuid" element={
                            <AppBackOffice loaded={loaded} logout={logout} profile={profile} memberData={memberData} />
                        } />
                    </Routes>
                </div>
                <Footer />
            </Router>
        </>
    );
}

export default App;