import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';
import { employee } from '../../../api/employee';

import { Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from '@mui/material';

import RowEmployee from "./RowEmployee";
import AddEmployee from './AddEmployee';

const TableEmployeesList = ({ data, reloadEmployees, listServices }) => {
    const cookies = new Cookies(null, { path: 'null' });
    
    const handleDeleteButton = async (id) => {
        try {
            const findIndex = data.findIndex((element) => element.id === id);

            if(findIndex === -1)
            {
                return;
            }

            Swal.fire({
                icon: 'warning',
                title: 'ยืนยันหรือไม่',
                text: `คุณจะลบชื่อผู้ใช้ ${data[findIndex].username} ใช่หรือไม่`,
                confirmButtonText: 'ยืนยัน',
                showCancelButton: true,
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if(!result.isConfirmed)
                {
                    return;
                }

                const token = cookies.get('access_token');
                const service = new employee();
                const deleteEmployee = await service.deleteEmployee(token, id);
                
                if(deleteEmployee.data.status_code === 200 && deleteEmployee.data.result)
                {
                    Swal.fire({
                        icon: 'success',
                        title: 'แจ้งเตือน',
                        text: `ลบชื่อผู้ใช้ ${data[findIndex].username} เรียบร้อยแล้วค่ะ`,
                        confirmButtonText: 'ตกลง'
                    });

                    return reloadEmployees();
                }
                else
                {
                    return Swal.fire({
                        icon: 'error',
                        title: 'แจ้งเตือน',
                        text: deleteEmployee.data.message || 'เกิดข้อผิดพลาดไม่ทราบสาเหตุ',
                        confirmButtonText: 'ตกลง'
                    });
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">ชื่อ</TableCell>
                        <TableCell align="center">ชื่อผู้ใช้งาน</TableCell>
                        <TableCell align="center">จัดการ</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.sort((a, b) => {
                            return new Date(b.createdAt) - new Date(a.createdAt);
                        }).map((row, index) => <RowEmployee row={row} reloadEmployees={reloadEmployees} handleDeleteButton={handleDeleteButton} listServices={listServices} key={`${index}-${row.username}`} />)
                    }
                    <AddEmployee reloadEmployees={reloadEmployees} />
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TableEmployeesList;