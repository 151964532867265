import { useState } from "react";
import { employee } from "../../../api/employee";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

import PageLoading from "../../Loading/PageLoading";

import { Grid, Typography, IconButton, Box, Collapse, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, OutlinedInput } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import AvaliableTime from "./AvaliableTime";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const dayData = [
    {
        dayEN: 'monday',
        dayTH: 'วันจันทร์'
    },
    {
        dayEN: 'tuesday',
        dayTH: 'วันอังคาร'
    },
    {
        dayEN: 'wednesday',
        dayTH: 'วันพุธ'
    },
    {
        dayEN: 'thursday',
        dayTH: 'วันพฤหัสบดี'
    },
    {
        dayEN: 'friday',
        dayTH: 'วันศุกร์'
    },
    {
        dayEN: 'saturday',
        dayTH: 'วันเสาร์'
    },
    {
        dayEN: 'sunday',
        dayTH: 'วันอาทิตย์'
    },
];

const ManageListService = ({ item, avaliableTime, reloadAvaliableTime, setAvaliableTime }) => {
    const cookies = new Cookies(null, { path: '/' });
    const [open, setOpen] = useState(false);

    const isValidTimeFormat = (timeString) => {
        var regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        return regex.test(timeString);
    }

    const containsOnlyDigits = (str) => {
        var regex = /^[0-9]+$/;
        return regex.test(str);
    }

    const deleteTime = async (id, time) => {
        let tmp_avaliable_data = [
            ...avaliableTime
        ];

        const findIndexDay = tmp_avaliable_data.findIndex((element) => element.id === id);
        if(findIndexDay === -1)
        {
            return;
        }
        
        const findIndexDayTH = dayData.findIndex((element) => element.dayEN === tmp_avaliable_data[findIndexDay].day);
        const day_show = findIndexDayTH === -1 ? tmp_avaliable_data[findIndexDay].day : dayData[findIndexDayTH].dayTH;

        return Swal.fire({
            icon: 'warning',
            title: 'ยืนยันหรือไม่',
            text: `คุณกำลังจะลบเวลา ${time} ของ ${day_show}`,
            confirmButtonText: 'ยืนยัน',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก'
        }).then(async (result) => {
            if(result.isConfirmed)
            {
                let tmp_avaliable_data_time = JSON.parse(tmp_avaliable_data[findIndexDay].data)
                tmp_avaliable_data_time = tmp_avaliable_data_time.filter((element) => element.time !== time);
                tmp_avaliable_data[findIndexDay].data = JSON.stringify(tmp_avaliable_data_time);

                setAvaliableTime([]);
                
                try {
                    const token = cookies.get('access_token');
                    const service = new employee();
                    const updateAvaliableTime = await service.updateAvaliableTime(token, tmp_avaliable_data);

                    if(updateAvaliableTime.data.status_code === 200)
                    {
                        Swal.fire({
                            icon: 'success',
                            title: 'แจ้งเตือน',
                            text: `ลบเวลา ${time} ของ ${day_show} เรียบร้อยแล้วค่ะ`,
                            confirmButtonText: 'ยืนยัน'
                        });
                        setAvaliableTime(updateAvaliableTime.data.data);
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        })
    }

    const updateTime = async (id, time, new_time, new_max) => {
        let tmp_avaliable_data = [
            ...avaliableTime
        ];

        const findIndexDay = tmp_avaliable_data.findIndex((element) => element.id === id);
        if(findIndexDay === -1)
        {
            return;
        }

        let tmp_avaliable_data_time = JSON.parse(tmp_avaliable_data[findIndexDay].data)
        const findIndexTime = tmp_avaliable_data_time.findIndex((element) => element.time === time);
        if(findIndexTime === -1)
        {
            return;
        }

        tmp_avaliable_data_time[findIndexTime].time = new_time;
        tmp_avaliable_data_time[findIndexTime].max = new_max;
        tmp_avaliable_data[findIndexDay].data = JSON.stringify(tmp_avaliable_data_time);
        
        setAvaliableTime([]);
        const findIndexDayTH = dayData.findIndex((element) => element.dayEN === tmp_avaliable_data[findIndexDay].day);
        const day_show = findIndexDayTH === -1 ? tmp_avaliable_data[findIndexDay].day : dayData[findIndexDayTH].dayTH;

        try {
            const token = cookies.get('access_token');
            const service = new employee();
            const updateAvaliableTime = await service.updateAvaliableTime(token, tmp_avaliable_data);

            if(updateAvaliableTime.data.status_code === 200)
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `แก้ไขเวลาเป็น ${new_time} และ จำกัดเป็น ${new_max} ของ ${day_show} เรียบร้อยแล้วค่ะ`,
                    confirmButtonText: 'ยืนยัน'
                });
                setAvaliableTime(updateAvaliableTime.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const addTime = async (id) => {
        const timeValue = document.getElementById(`reservation_add_time_${item.id}_${id}`).value;
        const maxValue = document.getElementById(`reservation_add_max_${item.id}_${id}`).value;

        if(timeValue === undefined || timeValue === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: `กรุณากรอกช่อง "เวลา" ด้วยค่ะ`,
                confirmButtonText: 'ตกลง'
            });
        }
        else if(maxValue === undefined || maxValue === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: `กรุณากรอกช่อง "จำกัด" ด้วยค่ะ`,
                confirmButtonText: 'ตกลง'
            });
        }
        else if(!isValidTimeFormat(timeValue))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: `กรุณากรอกช่อง "เวลา" ให้อยู่ในรูปแบบ "xx:xx" ด้วยค่ะ (ตัวอย่าง 20:00)`,
                confirmButtonText: 'ตกลง'
            });
        }
        else if(!containsOnlyDigits(maxValue))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: `กรุณากรอกช่อง "จำกัด" ให้มีเฉพาะตัวเลขด้วยค่ะ`,
                confirmButtonText: 'ตกลง'
            });
        }

        let tmp_avaliable_data = [
            ...avaliableTime
        ];

        const findIndexDay = tmp_avaliable_data.findIndex((element) => element.id === id);
        if(findIndexDay === -1)
        {
            return;
        }

        const findIndexDayTH = dayData.findIndex((element) => element.dayEN === tmp_avaliable_data[findIndexDay].day && tmp_avaliable_data[findIndexDay].service_id === item.id);
        const day_show = findIndexDayTH === -1 ? tmp_avaliable_data[findIndexDay].day : dayData[findIndexDayTH].dayTH;

        const findTimeInData = JSON.parse(tmp_avaliable_data[findIndexDay].data).findIndex((element) => element.time === timeValue);
        if(findTimeInData !== -1)
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: `ไม่สามารถเพิ่มเวลา ${timeValue} ลง ${day_show} ได้เนื่องจากมีอยู่ในระบบอยู่แล้วค่ะ`,
                confirmButtonText: 'ตกลง'
            });
        }

        try {
            const token = cookies.get('access_token');
            const tmp_avaliable_data_time = JSON.parse(tmp_avaliable_data[findIndexDay].data);
            tmp_avaliable_data_time.push({time: timeValue, max: maxValue});
            tmp_avaliable_data[findIndexDay].data = JSON.stringify(tmp_avaliable_data_time);
            
            const service = new employee();
            const updateAvaliableTimeData = await service.updateAvaliableTimeData(token, id, tmp_avaliable_data[findIndexDay].data);

            if(updateAvaliableTimeData.data.status_code === 200 && updateAvaliableTimeData.data.result)
            {
                setAvaliableTime(tmp_avaliable_data);
                document.getElementById(`reservation_add_time_${item.id}_${id}`).value = "";
                document.getElementById(`reservation_add_max_${item.id}_${id}`).value = "";

                return Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `เพิ่มเวลา ${timeValue} (จำกัด ${maxValue}) ลง ${day_show} ของบริการ ${item.name} เรียบร้อยแล้วค่ะ`,
                    confirmButtonText: 'ตกลง'
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Box>
            <Grid item xs={12}>
                <Typography fontSize={20}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {
                            open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                        }
                    </IconButton>
                    บริการ { item.name }
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={open} timeout={0}>
                    {
                        avaliableTime.filter((element) => element.service_id === item.id).length > 0 ? <Box px={3}>
                            <Grid container spacing={2}>
                                {
                                    avaliableTime.filter((element) => element.service_id === item.id).map((item_time, index) => {
                                        const findIndexDayTH = dayData.findIndex((element) => element.dayEN === item_time.day);
                                        const day_show = findIndexDayTH === -1 ? item_time.day : dayData[findIndexDayTH].dayTH;

                                        return (
                                            <Grid item xs={12} key={`avaliable-time-${item_time.id}-${index}`}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography fontSize={18}>
                                                            {day_show}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TableContainer component={Paper}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell align="center">เวลา</TableCell>
                                                                        <TableCell align="center">จำกัด</TableCell>
                                                                        <TableCell />
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        JSON.parse(item_time.data).length > 0 ? JSON.parse(item_time.data).sort((a, b) => {
                                                                            return a.time.localeCompare(b.time);
                                                                        }).map((row, dataIndex) => {
                                                                            return <AvaliableTime item={item_time} row={row} dataIndex={dataIndex} deleteTime={deleteTime} updateTime={updateTime} key={`manage-time-${item_time.id}-${dataIndex}`} />
                                                                        }) : <TableRow
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell colSpan={3} align="center">
                                                                                ยังไม่มีเวลาที่เปิดให้บริการ ณ { day_show }
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    }
                                                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0, backgroundColor: 'rgba(32, 112, 181, .1)' } }}>
                                                                        <TableCell align="center">
                                                                            <OutlinedInput
                                                                                name={`reservation_add_time_${item.id}_${item_time.id}`}
                                                                                id={`reservation_add_time_${item.id}_${item_time.id}`}
                                                                                type={`text`}
                                                                                size='small'
                                                                                autoComplete={`reservation_add_time_${item.id}_${item_time.id}`}
                                                                                placeholder="เวลา"
                                                                                // inputRef={timeRef}
                                                                            />
                                                                            <Typography fontSize={12} color={`rgba(0, 0, 0, .5)`}>
                                                                                ตัวอย่าง 12:30
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <OutlinedInput
                                                                                name={`reservation_add_max_${item.id}_${item_time.id}`}
                                                                                id={`reservation_add_max_${item.id}_${item_time.id}`}
                                                                                type={`text`}
                                                                                size='small'
                                                                                autoComplete={`reservation_add_max_${item.id}_${item_time.id}`}
                                                                                placeholder="ตัวอย่าง: 0"
                                                                                // inputRef={maxRef}
                                                                            />
                                                                            <Typography fontSize={12} color={`rgba(0, 0, 0, .5)`}>
                                                                                หากเป็น 0 จะเท่ากับ ไม่จำกัด
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <IconButton onClick={() => addTime(item_time.id)} sx={
                                                                                {
                                                                                    backgroundColor: '#134169',
                                                                                    m: .5,
                                                                                    borderRadius: 3,
                                                                                    '&:hover': {
                                                                                        backgroundColor: '#134169'
                                                                                    }
                                                                                }
                                                                            }>
                                                                                <AddIcon fontSize="small" sx={{ color: 'white' }} />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box> : <PageLoading />
                    }
                </Collapse>
            </Grid>
        </Box>
    )
}

export default ManageListService;