import { Box, Container, Grid, Typography } from "@mui/material";
import MenuButton from "./MenuButton";

// ICONS
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimelineIcon from '@mui/icons-material/Timeline';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PageLoading from "../Loading/PageLoading";

const Home = (props) => {
    return (
        <Container sx={
            {
                py: 3
            }
        }>
            {
                props.loaded ? <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Box width={`100%`} sx={
                                {
                                    backgroundColor: `#d3c5b8`,
                                    color: `white`,
                                    borderRadius: 3,
                                    boxShadow: '0 1rem 2rem rgba(0, 0, 0, .175)'
                                }
                            }>
                                <Box p={3}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <img src={props.profile.pictureUrl} alt="ProfileImage" style={
                                                {
                                                    width: `100%`,
                                                    height: `auto`,
                                                    borderRadius: 100
                                                }
                                            } />
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8}>
                                            <Typography fontSize={`large`} display={`flex`} alignItems={`center`} color={`#4b3320`}>
                                                ยินดีต้อนรับคุณ {props.profile.displayName}
                                            </Typography>
                                            <Typography display={`flex`} alignItems={`center`} color={`#4b3320`}>
                                                คะแนนสะสม: { parseFloat(props.memberData.points).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }&nbsp;<MonetizationOnIcon fontSize="small" />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <MenuButton title={`จองล่วงหน้า`} icon={<AccessTimeIcon fontSize="large" sx={{ color: '#4b3320' }} />} link={`/reservation`} />
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <MenuButton title={`ประวัติการจอง`} icon={<TimelineIcon fontSize="large" sx={{ color: '#4b3320' }} />} link={`/history-reservation`} />
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4}>
                                    <MenuButton title={`คะแนนสะสม`} icon={<MonetizationOnIcon fontSize="large" sx={{ color: '#4b3320' }} />} link={`/history-points`} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container> : <PageLoading />
            }
        </Container>
    )
}

export default Home