import { Box, Button, Container, Divider, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Cookies from "universal-cookie";
import Hamburger from 'hamburger-react'

const menuLink = [
    {
        label: 'หน้าหลัก',
        to: '/backoffice'
    },
    {
        label: 'ผู้ใช้งานในระบบ',
        to: '/backoffice/members'
    },
    {
        label: 'จัดการพนักงาน',
        to: '/backoffice/employee'
    },
    {
        label: 'วันและเวลาเปิดให้บริการ',
        to: '/backoffice/managedayavaliable'
    },
    {
        label: 'วันที่ไม่ต้องการให้จอง',
        to: '/backoffice/managedayblocked'
    },
    {
        label: 'จัดการบริการ',
        to: '/backoffice/manageservices'
    },
    {
        label: 'ออกจากระบบ',
        to: '/backoffice/logout',
        onlyMobile: true
    }
];

const Navbar = ({ loaded }) => {
    const cookies = new Cookies(null, { path: '/' });

    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);

    const handleLogout = () => {
        cookies.remove('access_token');
        window.location.reload();
    }

    return (
        loaded ? <>
            <Box position={`fixed`} width={`100%`} minHeight={73} bgcolor={`#d3c5b8`} color={`#4b3320`} zIndex={11} sx={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)" }}>
                <Container maxWidth={`1920px`} sx={
                    {
                        height: `100%`,
                        maxWidth: 1920
                    }
                }>
                    <Box sx={
                        {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: `center`,
                            height: 73
                        }
                    }>
                        <Box sx={
                            {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: `center`,
                                width: `auto`,
                                height: `100%`
                            }
                        }>
                            {/* START ALL RESPONSIVE */}
                            <Link to="/backoffice" style={{ color: '#4b3320' }}>
                                {
                                    String(`Sabaidee Health Massage`).toLocaleUpperCase()
                                }
                            </Link>
                        </Box>
                        <Box sx={
                            {
                                display: {
                                    lg: 'flex',
                                    md: 'flex',
                                    sm: 'none',
                                    xs: 'none'
                                },
                                justifyContent: 'space-between',
                                alignItems: `center`,
                                width: `auto`,
                                height: `100%`,
                            }
                        }>
                            {/* CENTER DESKTOP RESPONSIVE */}
                            {
                                menuLink.map((item, index) =>
                                    !item.onlyMobile && <Link to={item.to} key={`${index}-${item.label}`} style={{ height: `100%` }}>
                                        <Box
                                            sx={
                                                {
                                                    height: `100%`,
                                                    color: '#4b3320',
                                                    cursor: 'pointer',
                                                    transition: 'all .2s',
                                                    '&:hover': {
                                                        backgroundColor: '#a0958b'
                                                    }
                                                }
                                            }
                                        >
                                                <Typography display={`flex`} justifyContent={`center`} alignItems={`center`} height={`100%`} px={2}>
                                                    {item.label}
                                                </Typography>
                                        </Box>
                                    </Link>
                                )
                            }
                        </Box>
                        <Box sx={
                            {
                                display: {
                                    lg: 'flex',
                                    md: 'flex',
                                    sm: 'none',
                                    xs: 'none'
                                },
                                justifyContent: 'space-between',
                                alignItems: `center`,
                                width: `auto`,
                                height: `100%`
                            }
                        }>
                            {/* END DESKTOP RESPONSIVE */}
                            <Button sx={{ backgroundColor: '#cc0000', color: 'white', '&:hover': { backgroundColor: '#990000', color: 'white' } }} onClick={handleLogout}>
                                ออกจากระบบ
                            </Button>
                        </Box>
                        <Box sx={
                            {
                                display: {
                                    lg: 'none',
                                    md: 'none',
                                    sm: 'flex',
                                    xs: 'flex'
                                }
                            }
                        }>
                            {/* CENTER MOBILE RESPONSIVE */}
                        </Box>
                        <Box sx={
                            {
                                display: {
                                    lg: 'none',
                                    md: 'none',
                                    sm: 'flex',
                                    xs: 'flex'
                                },
                                justifyContent: 'space-between',
                                alignItems: `center`,
                                width: `auto`,
                                height: `100%`
                            }
                        }>
                            {/* END MOBILE RESPONSIVE */}
                            <Hamburger toggled={isOpenMobileMenu} toggle={setIsOpenMobileMenu} />
                            {/* <Typography mr={1}>
                                {
                                    props.profile.displayName
                                }
                            </Typography>
                            <img src={props.profile.pictureUrl} alt="pictureProfile" width={40} height={40} style={{ borderRadius: 25 }} /> */}
                        </Box>
                    </Box>
                    <Box sx={{ display: isOpenMobileMenu ? 'block' : 'none' }}>
                        {
                            menuLink.map((item, index) => (
                                <Box key={`mobile-link-${index}-${item.label}`}>
                                    <Link to={item.to} style={{ height: `100%`, color: 'white' }}>
                                        <Typography display={`flex`} justifyContent={`center`} alignItems={`center`} height={`100%`} fontSize={`large`} mb={1}>
                                            { item.label }
                                        </Typography>
                                    </Link>
                                    {/* {
                                        index !== (menuLink.length - 1) ? <Divider sx={{ mb: 1 }} /> : <Divider sx={{ mb: 1 }} />
                                    } */}
                                    <Divider sx={{ mb: 1 }} /> 
                                </Box>
                            ))
                        }
                    </Box>
                </Container>
            </Box>
        </> : null
    )
}

export default Navbar;