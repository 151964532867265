import { useEffect, useState } from "react";
import { api } from "../../api/reservation";
import PageLoading from '../Loading/PageLoading';

import { Grid, Button } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// MUI ICONS
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CheckIcon from '@mui/icons-material/Check';

const ChooseTime = ({ selectedTime, setSelectedTime, selectedDate, setStepCount, selectedService }) => {
    const [timeAvaliable, setTimeAvaliable] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        reloadTimeAvaliable();
    }, [selectedDate]);

    const reloadTimeAvaliable = async () => {
        try {
            setLoading(true);
            const service = new api();
            const result = await service.getTimeAvaliable(selectedDate, selectedService);

            if(result.data.status_code === 200)
            {
                setTimeAvaliable(result.data.data);
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleSelectTime = (time) => {
        if(time === selectedTime)
        {
            return setSelectedTime(null);
        }

        setSelectedTime(time);
    }

    const handleNextStep = () => {
        if(setSelectedTime === null)
        {
            return;
        }

        setStepCount(3);
    }

    const handleBackStep = () => {
        setSelectedTime(null);
        setStepCount(1);
    }

    return (
        <Grid container spacing={1} sx={{ mt: 2 }}>
            {
                !loading ? <>
                    <Grid item xs={12} sm={12} md={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">เวลา</TableCell>
                                        <TableCell align="center">สถานะ</TableCell>
                                        <TableCell align="center">เลือก</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        timeAvaliable.length > 0 ? timeAvaliable.sort((a, b) => {
                                            return a.time.localeCompare(b.time);
                                        }).map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">
                                                    {row.time}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {
                                                        row.status === false ? "ว่าง" : "เต็ม"
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button onClick={() => handleSelectTime(row.time)} disabled={row.status} sx={
                                                        {
                                                            backgroundColor: selectedTime === row.time ? `#a0958b` : `#d3c5b8`,
                                                            color: '#4b3320',
                                                            '&:hover': {
                                                                backgroundColor: `#a0958b`,
                                                                color: '#4b3320',
                                                            },
                                                            '&:disabled': {
                                                                color: 'rgba(255, 255, 255, .5)',
                                                            }
                                                        }
                                                    }>
                                                        {
                                                            selectedTime === row.time ? <CheckIcon /> : row.status ? 'เต็ม' : 'เลือก'
                                                        }
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell colSpan={3} align="center">
                                                ไม่มีเวลาให้จองของวันนี้
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button fullWidth onClick={handleNextStep} disabled={selectedTime === null} sx={
                            {
                                backgroundColor: `#d3c5b8`,
                                color: '#4b3320',
                                '&:hover': {
                                    backgroundColor: `#a0958b`,
                                    color: '#4b3320',
                                },
                                '&:disabled': {
                                    color: 'rgba(255, 255, 255, .5)',
                                }
                            }
                        }>
                            ถัดไป <NavigateNextIcon />
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button fullWidth onClick={handleBackStep} sx={
                            {
                                backgroundColor: `#d3c5b8`,
                                color: '#4b3320',
                                '&:hover': {
                                    backgroundColor: `#a0958b`,
                                    color: '#4b3320',
                                }
                            }
                        }>
                            <ChevronLeftIcon /> ย้อนกลับ
                        </Button>
                    </Grid>
                </> : <PageLoading />
            }
        </Grid>
    )
}

export default ChooseTime;