import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { api } from "../../../api/reservation";
import { employee } from "../../../api/employee";
import Cookies from "universal-cookie";

import { Container, Box, Grid, Typography, Divider, FormControl, FormLabel, OutlinedInput, Button, Paper, IconButton } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CancelIcon from '@mui/icons-material/Cancel';
import RedeemIcon from '@mui/icons-material/Redeem';

import Navbar from "../Navbar/Navbar";
import PageLoading from "../../Loading/PageLoading";

const unicodeToText = (text) => {
    return text.replace(/\\u[\dA-F]{4}/gi, function(unicode) {
        const charCode = parseInt(unicode.replace(/\\u/g, ''), 16);
        return String.fromCodePoint(charCode);
    });
}

const checkString = (inputString) => {
    var regex = /^[0-9.]+$/;

    if(regex.test(inputString))
    {
        return true;
    }
    else
    {
        return false;
    }
}

const EditMember = ({ members, loaded, reloadMembers }) => {
    const cookies = new Cookies(null, { path: '/' });
    const { line_uuid } = useParams();

    const [loading, setLoading] = useState(true);
    const [memberData, setMemberData] = useState({});

    const pointsInputRef = useRef(null);
    const givePointsInputRef = useRef(null);
    const reasonGivePoints = useRef(null);

    useEffect(() => {
        const memberIndex = members.findIndex((element) => element.line_uuid === line_uuid);
        if(memberIndex === -1)
        {
            return;
        }

        if(pointsInputRef.current !== null)
        {
            pointsInputRef.current.value = members[memberIndex].points;
        }

        setMemberData(members[memberIndex]);
        setLoading(false);
    }, [line_uuid, members]);

    const handleEditPoints = async () => {
        const points = pointsInputRef.current.value;

        if(points === undefined || points === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'ช่องคะแนนสะสม ห้ามเว้นว่างค่ะ',
                confirmButtonText: 'ตกลง'
            });
        }

        if(!checkString(points))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'กรุณากรอกเฉพาะตัวเลขและทศนิยมเท่านั้นค่ะ',
                confirmButtonText: 'ตกลง'
            });
        }

        try {
            const service = new employee();
            const editMemberPoints = await service.editMemberPoints(cookies.get('access_token'), memberData.line_uuid, points);

            if(editMemberPoints.data.status_code === 200)
            {
                reloadMembers();
                
                return Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `แก้ไข Points ของคุณ ${unicodeToText(memberData.displayName)} เป็น ${parseFloat(points).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} เรียบร้อยแล้วค่ะ`,
                    confirmButtonText: 'ตกลง'
                });
            }
            else
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: editMemberPoints.data.message || 'เกิดข้อผิดพลาดไม่ทราบสาเหตุ',
                    confirmButtonText: 'ตกลง'
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    const givePointsToMember = async () => {
        const points = givePointsInputRef.current.value;
        const reason = reasonGivePoints.current.value;

        if(points === undefined || points === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'ช่องคะแนนที่จะให้ ห้ามเว้นว่างค่ะ',
                confirmButtonText: 'ตกลง'
            });
        }

        if(!checkString(points))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'กรุณากรอกเฉพาะตัวเลขและทศนิยมเท่านั้นค่ะ',
                confirmButtonText: 'ตกลง'
            });
        }

        try {
            const service = new employee();
            const addMemberPoints = await service.giveMemberPoints(cookies.get('access_token'), memberData.line_uuid, points, reason);

            if(addMemberPoints.data.status_code === 200)
            {
                givePointsInputRef.current.value = "0.00";
                reasonGivePoints.current.value = "";
                reloadMembers();

                return Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `เพิ่ม Points ให้คุณ ${unicodeToText(memberData.displayName)} จำนวน ${parseFloat(points).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} เรียบร้อยแล้วค่ะ`,
                    confirmButtonText: 'ตกลง'
                });
            }
            else
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: addMemberPoints.data.message || 'เกิดข้อผิดพลาดไม่ทราบสาเหตุ',
                    confirmButtonText: 'ตกลง'
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    const cancelReservation = async (id) => {
        try {
            const findIndexReservation = memberData.history.findIndex((element) => element.id === id);
            if(findIndexReservation === -1)
            {
                return;
            }
    
            const date = new Date(memberData.history[findIndexReservation].date);
            const reservation_date = `${(String(date.getDate())).length > 1 ? date.getDate() : `0${date.getDate()}`}/${(String(date.getMonth() + 1)).length > 1 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}/${date.getFullYear()}`;
            const timeString = memberData.history[findIndexReservation].time;
            const timeParts = timeString.split(":");
            const formattedTime = `${timeParts[0]}:${timeParts[1]}`;
    
            Swal.fire({
                icon: 'warning',
                title: 'ยืนยันหรือไม่',
                text: `คุณต้องการจะยกเลิกการจองของวันที่ ${reservation_date} เวลา ${formattedTime} ใช่หรือไม่`,
                confirmButtonText: 'ยืนยัน',
                showCancelButton: true,
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if(result.isConfirmed)
                {
                    const service = new api();
                    const cancelReservation = await service.cancelReservation(id, memberData.line_uuid);

                    if(cancelReservation.data.status_code === 200 && cancelReservation.data.result)
                    {
                        reloadMembers();
                        return Swal.fire({
                            icon: 'success',
                            title: `ยกเลิกการจองสำเร็จ`,
                            text: `ยกเลิกการจองของวันที่ ${reservation_date} เวลา ${formattedTime} เรียบร้อยแล้วค่ะ`,
                            confirmButtonText: 'ยืนยัน'
                        });
                    }
                }
            })
        } catch (err) {
            console.log(err);
        }
    }

    return (
        loaded ? <>
            <Navbar loaded={loaded} />
            <Container sx={{ pt: 12, mb: 3 }}>
                <Box width={`100%`} bgcolor={`white`} borderRadius={3} sx={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.2)" }}>
                    <Box p={3}>
                        {
                            !loading ? <>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography fontSize={18}>
                                            บัญชีของคุณ { unicodeToText(memberData.displayName) }
                                        </Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box width={`100%`} display={`flex`} justifyContent={`center`}>
                                            <img src={memberData.pictureUrl} alt={`avartar-${line_uuid}`} style={{ maxWidth: 150, borderRadius: 300 }} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl fullWidth>
                                            <FormLabel htmlFor="member_detail_uuid">
                                                UUID
                                            </FormLabel>
                                            <OutlinedInput
                                                name='member_detail_uuid'
                                                type={`text`}
                                                size='small'
                                                fullWidth
                                                autoComplete="member_detail_uuid"
                                                disabled
                                                value={memberData.line_uuid}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Divider>
                                            คะแนน
                                        </Divider>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                                <FormControl fullWidth>
                                                    <FormLabel htmlFor="member_detail_points">
                                                        คะแนนสะสมปัจจุบัน
                                                    </FormLabel>
                                                    <OutlinedInput
                                                        name='member_detail_points'
                                                        type={`text`}
                                                        size='small'
                                                        fullWidth
                                                        autoComplete="member_detail_points"
                                                        defaultValue={memberData.points}
                                                        inputRef={pointsInputRef}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6} lg={6} height={`auto`}>
                                                <Box display={`flex`} alignItems={`end`} height={`100%`}>
                                                    <Button fullWidth onClick={handleEditPoints} sx={
                                                        {
                                                            backgroundColor: `#d3c5b8`,
                                                            color: '#4b3320',
                                                            mb: .3,
                                                            '&:hover': {
                                                                backgroundColor: `#a0958b`,
                                                                color: '#4b3320',
                                                            },
                                                            '&:disabled': {
                                                                color: 'rgba(255, 255, 255, .5)',
                                                            }
                                                        }
                                                    }>
                                                        <ModeEditIcon />&nbsp;แก้ไขคะแนน
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                                <FormControl fullWidth>
                                                    <FormLabel htmlFor="member_detail_give_points">
                                                        คะแนนที่จะให้
                                                    </FormLabel>
                                                    <OutlinedInput
                                                        name='member_detail_give_points'
                                                        type={`text`}
                                                        size='small'
                                                        fullWidth
                                                        autoComplete="member_detail_give_points"
                                                        placeholder="ตัวอย่าง 50.00"
                                                        defaultValue={`0.00`}
                                                        inputRef={givePointsInputRef}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                                <FormControl fullWidth>
                                                    <FormLabel htmlFor="member_detail_reason_give_points">
                                                        หมายเหตุ
                                                    </FormLabel>
                                                    <OutlinedInput
                                                        name='member_detail_reason_give_points'
                                                        type={`text`}
                                                        size='small'
                                                        fullWidth
                                                        autoComplete="member_detail_reason_give_points"
                                                        placeholder="ไม่จำเป็น"
                                                        inputRef={reasonGivePoints}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={4} height={`auto`}>
                                                <Box display={`flex`} alignItems={`end`} height={`100%`}>
                                                    <Button fullWidth onClick={givePointsToMember} sx={
                                                        {
                                                            backgroundColor: `#d3c5b8`,
                                                            color: '#4b3320',
                                                            mb: .3,
                                                            '&:hover': {
                                                                backgroundColor: `#a0958b`,
                                                                color: '#4b3320',
                                                            },
                                                            '&:disabled': {
                                                                color: 'rgba(255, 255, 255, .5)',
                                                            }
                                                        }
                                                    }>
                                                        <RedeemIcon />&nbsp;ให้คะแนน
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Divider>
                                            ประวัติการจอง
                                        </Divider>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">ชื่อผู้จอง</TableCell>
                                                        <TableCell align="center">เบอร์โทรศัพท์</TableCell>
                                                        <TableCell align="center">วันเวลา</TableCell>
                                                        <TableCell align="center">จัดการ</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        memberData.history.length > 0 ? memberData.history.sort((a, b) => new Date(a.date_time) - new Date(b.date_time)).map((row, index) => {
                                                            const date = new Date(row.date);
                                                            const reservation_date = `${(String(date.getDate())).length > 1 ? date.getDate() : `0${date.getDate()}`}/${(String(date.getMonth() + 1)).length > 1 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}/${date.getFullYear()}`;
                                                            const timeString = row.time;
                                                            const timeParts = timeString.split(":");
                                                            const formattedTime = `${timeParts[0]}:${timeParts[1]}`;
                                                            
                                                            return (
                                                                <TableRow
                                                                    key={index}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell align="center">
                                                                        { row.name }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        { row.phone }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        { reservation_date } { formattedTime }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <IconButton size="small" onClick={() => cancelReservation(row.id)} sx={
                                                                            {
                                                                                backgroundColor: '#f20000',
                                                                                color: '#4b3320',
                                                                                borderRadius: 2,
                                                                                '&:hover': {
                                                                                    backgroundColor: '#cc0000',
                                                                                    color: '#4b3320',
                                                                                }
                                                                            }
                                                                        }>
                                                                            <CancelIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        }) : <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell colSpan={4} align="center">
                                                                ไม่มีประวัติการจอง
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </> : <PageLoading />
                        }
                    </Box>
                </Box>
            </Container>
        </> : <PageLoading />
    )
}

export default EditMember;