import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { api } from "../../api/reservation";

import { Container, Paper, Divider, Typography, Box, Button, IconButton } from "@mui/material"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// COMPONENTS
import PageLoading from "../Loading/PageLoading";

// MUI ICONS
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CancelIcon from '@mui/icons-material/Cancel';

const HistoryReservation = ({ loaded, profile }) => {
    const navigate = useNavigate();
    const [processing, setProccessing] = useState(true);
    const [dataHistoryReservation, setDataHistoryReservation] = useState([]);

    useEffect(() => {
        reloadHistoryReservation();
    }, [profile]);

    const reloadHistoryReservation = async () => {
        try {
            const service = new api();
            const getHistory = await service.getHistoryReservation(profile.userId);

            if(getHistory.data.history !== undefined)
            {
                setDataHistoryReservation(getHistory.data.history);
            }

            setProccessing(false);
        } catch (err) {
            console.log(err);
        }
    }

    const backToHome = () => {
        navigate('/');
    }

    const cancelReservation = async (id) => {
        try {
            const findIndexReservation = dataHistoryReservation.findIndex((element) => element.id === id);
            if(findIndexReservation === -1)
            {
                return;
            }

            const date = new Date(dataHistoryReservation[findIndexReservation].date);
            const reservation_date = `${(String(date.getDate())).length > 1 ? date.getDate() : `0${date.getDate()}`}/${(String(date.getMonth() + 1)).length > 1 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}/${date.getFullYear()}`;
            const timeString = dataHistoryReservation[findIndexReservation].time;
            const timeParts = timeString.split(":");
            const formattedTime = `${timeParts[0]}:${timeParts[1]}`;

            Swal.fire({
                icon: 'warning',
                title: 'ยืนยันหรือไม่',
                text: `คุณต้องการจะยกเลิกการจองของวันที่ ${reservation_date} เวลา ${formattedTime} ใช่หรือไม่`,
                confirmButtonText: 'ยืนยัน',
                showCancelButton: true,
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if(result.isConfirmed)
                {
                    const service = new api();
                    const cancelReservation = await service.cancelReservation(id, profile.userId);

                    if(cancelReservation.data.status_code === 200 && cancelReservation.data.result)
                    {
                        reloadHistoryReservation();
                        return Swal.fire({
                            icon: 'success',
                            title: `ยกเลิกการจองสำเร็จ`,
                            text: `ยกเลิกการจองของวันที่ ${reservation_date} เวลา ${formattedTime} เรียบร้อยแล้วค่ะ`,
                            confirmButtonText: 'ยืนยัน'
                        });
                    }
                }
            })
        } catch (err) {
            console.log(err);
        }
    }

    return (
        loaded ? <Container sx={
            {
                py: 3
            }
        }>
            <Paper sx={{ p: 2 }}>
                <Typography fontSize={`large`} display={`flex`} justifyContent={`center`}>
                    ประวัติการจอง
                </Typography>
                <Divider sx={{ mb: 2 }} />
                {
                    processing ? <PageLoading /> : <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">ชื่อผู้จอง</TableCell>
                                    <TableCell align="center">เบอร์โทรศัพท์</TableCell>
                                    <TableCell align="center">วันเวลา</TableCell>
                                    <TableCell align="center">บริการ</TableCell>
                                    <TableCell align="center">จัดการ</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    dataHistoryReservation.length > 0 ? dataHistoryReservation.sort((a, b) => new Date(a.date_time) - new Date(b.date_time)).map((row, index) => {
                                        const date = new Date(row.date);
                                        const reservation_date = `${(String(date.getDate())).length > 1 ? date.getDate() : `0${date.getDate()}`}/${(String(date.getMonth() + 1)).length > 1 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}/${date.getFullYear()}`;
                                        const timeString = row.time;
                                        const timeParts = timeString.split(":");
                                        const formattedTime = `${timeParts[0]}:${timeParts[1]}`;
                                        
                                        return (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">
                                                    { row.name }
                                                </TableCell>
                                                <TableCell align="center">
                                                    { row.phone }
                                                </TableCell>
                                                <TableCell align="center">
                                                    { reservation_date } { formattedTime }
                                                </TableCell>
                                                <TableCell align="center">
                                                    { row.service_name }
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton size="small" onClick={() => cancelReservation(row.id)} sx={
                                                        {
                                                            backgroundColor: '#f20000',
                                                            color: 'white',
                                                            borderRadius: 2,
                                                            '&:hover': {
                                                                backgroundColor: '#cc0000',
                                                                color: 'white',
                                                            }
                                                        }
                                                    }>
                                                        <CancelIcon fontSize="small" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }) : <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell colSpan={5} align="center">
                                            ไม่มีประวัติการจอง
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                <Box width={`100%`} mt={2}>
                    <Button fullWidth onClick={backToHome} sx={
                        {
                            backgroundColor: `#d3c5b8`,
                            color: '#4b3320',
                            '&:hover': {
                                backgroundColor: `#a0958b`,
                                color: '#4b3320',
                            }
                        }
                    }>
                        <ChevronLeftIcon /> กลับหน้าหลัก
                    </Button>
                </Box>
            </Paper>
        </Container> : <PageLoading />
    )
}

export default HistoryReservation;