import { useEffect, useState, useRef } from "react";
import { api } from "../../../api/reservation";
import { employee } from "../../../api/employee";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

import { Container, Box, Grid, TableHead, TableContainer, TableRow, TableCell, TableBody, Paper, Table, OutlinedInput, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import Navbar from "../Navbar/Navbar";
import PageLoading from "../../Loading/PageLoading";
import TableRowManageService from "./TableRowManageService";

const ManageService = ({ loaded }) => {
    const cookies = new Cookies(null, { path: '/' });
    const [listServices, setListServices] = useState([]);
    const serviceNameInputRef = useRef(null);
    const servicePriceInputRef = useRef(null);

    useEffect(() => {
        reloadService();
    }, []);

    const reloadService = async () => {
        try {
            const service = new api();
            const getListServices = await service.getListServices();
            
            if(getListServices.data.status_code === 200)
            {
                setListServices(getListServices.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const deleteService = async (service_id) => {
        const findIndexService = listServices.findIndex((element) => element.id === service_id);
        if(findIndexService === -1)
        {
            return;
        }

        Swal.fire({
            icon: 'warning',
            title: 'ยืนยันที่จะลบหรือไม่',
            text: `หากคุณยืนยันที่จะลบบริการ ${listServices[findIndexService].name} ข้อมูลที่เกี่ยวกับบริการ ${listServices[findIndexService].name} จะถูกลบไปด้วยทั้งหมด`,
            confirmButtonText: 'ยืนยัน',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก'
        }).then(async (result) => {
            if(!result.isConfirmed)
            {
                return;
            }

            try {
                const token = cookies.get('access_token');
                const service = new employee();
                const deleteService = await service.deleteService(token, service_id);
                
                if(deleteService.data.status_code === 200 && deleteService.data.result)
                {
                    Swal.fire({
                        icon: 'success',
                        title: 'แจ้งเตือน',
                        text: `ลบบริการ ${listServices[findIndexService].name} เรียบร้อยแล้วค่ะ`
                    });
                    reloadService();
                }
            } catch (err) {
                console.log(err);
            }
        });
    }

    const editService = async (service_name, service_price, service_id, setOpen) => {
        if(service_name === undefined || service_name === '' || service_price === undefined || service_price === '')
        {
            return;
        }

        const findIndexService = listServices.findIndex((element) => element.id === service_id);
        if(findIndexService === -1)
        {
            return;
        }

        try {
            const token = cookies.get('access_token');
            const service = new employee();
            const editService = await service.editService(token, service_name, service_price, service_id);
            
            if(editService.data.status_code === 200 && editService.data.result)
            {
                setOpen(false);
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `แก้ไขเรียบร้อยแล้วค่ะ`
                });
                reloadService();
            }
        } catch (err) {
            console.log(err);
        }
    }

    const addService = async () => {
        const service_name = serviceNameInputRef.current.value;
        const service_price = servicePriceInputRef.current.value
        if(service_name === undefined || service_name === '' || service_price === undefined || service_price === '')
        {
            return;
        }

        try {
            const token = cookies.get('access_token');
            const service = new employee();
            const addService = await service.addService(token, service_name, service_price);

            if(addService.data.status_code === 200 && addService.data.result)
            {
                document.getElementById('add_service_input').value = '';
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `เพิ่มบริการ ${service_name} เรียบร้อยแล้วค่ะ`
                });
                reloadService();
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        loaded ? <>
            <Navbar loaded={loaded} />
            <Container sx={{ pt: 12, mb: 3 }}>
                <Box width={`100%`} bgcolor={`white`} borderRadius={3} sx={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.2)" }}>
                    <Box p={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">
                                                    บริการ
                                                </TableCell>
                                                <TableCell align="center">
                                                    ราคา
                                                </TableCell>
                                                <TableCell align="center">จัดการ</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                listServices.length > 0 ? listServices.sort((a,b) => a.id - b.id).map((item, index) => <TableRowManageService item={item} editService={editService} deleteService={deleteService} key={`row-service-${index}`} />) : <TableRow>
                                                    <TableCell align="center" colSpan={3}>
                                                        <PageLoading />
                                                    </TableCell>
                                                </TableRow>
                                            }
                                            <TableRow sx={{ backgroundColor: 'rgba(32, 112, 181, .1)' }}>
                                                <TableCell align="center">
                                                    <OutlinedInput
                                                        name={`add_service_input`}
                                                        id={`add_service_input`}
                                                        type={`text`}
                                                        size='small'
                                                        autoComplete={`add_service_input`}
                                                        placeholder="ชื่อบริการ"
                                                        inputRef={serviceNameInputRef}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <OutlinedInput
                                                        name={`add_service_price_input`}
                                                        id={`add_service_price_input`}
                                                        type={`text`}
                                                        size='small'
                                                        autoComplete={`add_service_price_input`}
                                                        placeholder="ราคาบริการ"
                                                        inputRef={servicePriceInputRef}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton onClick={addService} sx={
                                                        {
                                                            backgroundColor: '#134169',
                                                            m: .5,
                                                            borderRadius: 3,
                                                            '&:hover': {
                                                                backgroundColor: '#134169'
                                                            }
                                                        }
                                                    }>
                                                        <AddIcon fontSize="small" sx={{ color: 'white' }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </> : <PageLoading />
    )
}

export default ManageService;