import axios from "axios";

export class api {
    constructor() {
        this.baseURL = process.env.REACT_APP_API_URL;
        this.response = {};
        this.instance = axios.create({
            baseURL: this.baseURL,
            timeout: 30000,
            timeoutErrorMessage: "Time out!",
        });
    }

    getBaseURL = () => {
        return this.baseURL;
    }
    
    get = async (path, config) => {
        return this.instance.get(path, config).then((res) => {
            return res;
        });
    }

    post = async (path, body, config) => {
        return this.instance.post(path, body, config).then((res) => {
            return res;
        });
    }

    getListServices = async () => {
        this.response = await this.instance.get('/reservation/get/services', {});

        return this.response;
    }

    getMemberDetail = async (line_uuid, displayName, pictureUrl) => {
        this.response = await this.instance.post('/members/getDetail', { line_uuid: line_uuid, displayName: displayName, pictureUrl: pictureUrl }, {});

        return this.response;
    }

    checkDateAvaliable = async (date) => {
        this.response = await this.instance.post('/reservation/checkDate', { date: date }, {});
        
        return this.response;
    }

    getTimeAvaliable = async (date, service_id) => {
        this.response = await this.instance.post('/reservation/getTimeAvaliable', { date: date, service_id: service_id }, {});

        return this.response;
    }

    checkTimeAvaliable = async (date, time, service_id, line_uuid) => {
        this.response = await this.instance.post('/reservation/checkTimeAvaliable', { date: date, time: time, service_id: service_id, line_uuid: line_uuid }, {});

        return this.response;
    }

    confirmReservation = async (date, time, name, phone, service_id, profile) => {
        this.response = await this.instance.post('/reservation/confirmReservation', { date: date, time: time, name: name, phone: phone, service_id: service_id, profile: profile }, {});

        return this.response;
    }

    getHistoryReservation = async (line_uuid) => {
        this.response = await this.instance.post('/reservation/getHistory', { line_uuid: line_uuid }, {});

        return this.response;
    }

    cancelReservation = async (reservation_id, line_uuid) => {
        this.response = await this.instance.post('/reservation/cancelReservation', { reservation_id: reservation_id, line_uuid: line_uuid }, {});

        return this.response;
    }

    getHistoryPoints = async (line_uuid) => {
        this.response = await this.instance.post('/members/get/historyPoints', { line_uuid: line_uuid }, {});

        return this.response;
    }
}