import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { default as Fuse } from 'fuse.js';

// import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { OutlinedInput, FormLabel, FormControl } from '@mui/material';

const unicodeToText = (text) => {
    return text.replace(/\\u[\dA-F]{4}/gi, function(unicode) {
        const charCode = parseInt(unicode.replace(/\\u/g, ''), 16);
        return String.fromCodePoint(charCode);
    });
}

const MembersTable = ({ row }) => {
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {
                            open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                        }
                    </IconButton>
                </TableCell>
                <TableCell align="left">
                    <Link to={`/backoffice/member/${row.line_uuid}`}>
                        <img src={row.pictureUrl} alt={`avartar-${row.displayName}`} style={{ width: `100%`, height: `auto`, maxWidth: 40, borderRadius: 20 }} />
                    </Link>
                </TableCell>
                <TableCell align="center">
                    <Link to={`/backoffice/member/${row.line_uuid}`} style={
                        {
                            color: '#134169',
                            '&:hover': {
                                color: '#092135'
                            }
                        }
                    }>
                        { unicodeToText(row.displayName) }
                    </Link>
                </TableCell>
                {/* <TableCell align="center">
                    { row.line_uuid }
                </TableCell> */}
                <TableCell align="center">
                    { parseFloat(row.points).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                ประวัติการจองของคุณ <Link to={`/backoffice/member/${row.line_uuid}`} style={
                                    {
                                        color: '#134169',
                                        '&:hover': {
                                            color: '#092135'
                                        }
                                    }
                                }>
                                    { unicodeToText(row.displayName) }
                                </Link>
                            </Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">ชื่อผู้จอง</TableCell>
                                        <TableCell align="center">เบอร์โทรศัพท์</TableCell>
                                        <TableCell align="center">วันเวลา</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        (row.history !== undefined && row.history.length > 0) ? row.history.sort((a, b) => new Date(a.date_time) - new Date(b.date_time)).map((historyRow, index) => {
                                            const date = new Date(historyRow.date);
                                            const reservation_date = `${(String(date.getDate())).length > 1 ? date.getDate() : `0${date.getDate()}`}/${(String(date.getMonth() + 1)).length > 1 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}/${date.getFullYear()}`;
                                            const timeString = historyRow.time;
                                            const timeParts = timeString.split(":");
                                            const formattedTime = `${timeParts[0]}:${timeParts[1]}`;

                                            return (
                                                <TableRow key={`history-${row.line_uuid}-${index}`}>
                                                    <TableCell align="center">
                                                        { historyRow.name }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        { historyRow.phone }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        { reservation_date } { formattedTime }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }) : <TableRow key={`history-${row.line_uuid}`}>
                                            <TableCell align="center" colSpan={3}>
                                                ยังไม่มีประวัติการจอง
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

export default ({ members }) => {
    const [membersData, setMembersData] = useState([]);
    const [defaultMembersData, setDefaultMembersData] = useState([]);
    const [searching, setSearching] = useState(false);

    useEffect(() => {
        setMembersData(members);
        setDefaultMembersData(members);
    }, [members]);

    const fuseResults = (keyword) => {
        const fuse = new Fuse(defaultMembersData, { keys: ['displayName'] });
        return fuse.search(keyword, {}).map((result) => ({ ...result.item }))
    }

    const handleChangeSearchValue = (e) => {
        if(e.target.value === undefined || e.target.value === '')
        {
            setSearching(false);
            return setMembersData(defaultMembersData);
        }

        const result = fuseResults(e.target.value);
        setMembersData(result);
        setSearching(true);
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} mb={searching ? 3 : null}>
                    <Box height={`100%`}>
                        <Typography fontSize={18} display={`flex`} alignItems={`center`} height={`100%`}>
                            ผู้ใช้งานในระบบทั้งหมด { parseFloat(defaultMembersData.length).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) } คน
                        </Typography>
                        {
                            searching && <Typography fontSize={14}>
                                ค้นหาแล้วพบ {membersData.length} รายการ
                            </Typography>
                        }
                    </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Box width={`100%`} display={`flex`} justifyContent={`flex-end`}>
                        <FormControl>
                            <FormLabel htmlFor="search_member_data">
                                ค้นหา
                            </FormLabel>
                            <OutlinedInput
                                name='search_member_data'
                                type={`text`}
                                size='small'
                                autoComplete="search_member_data"
                                onChange={handleChangeSearchValue}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="left">รูปภาพ</TableCell>
                                    <TableCell align="center">ชื่อไลน์</TableCell>
                                    {/* <TableCell align="center">UUID</TableCell> */}
                                    <TableCell align="center">คะแนนสะสม</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    membersData.sort((a, b) => {
                                        return new Date(b.createdAt) - new Date(a.createdAt);
                                    }).map((row, index) => {
                                        return (
                                            <MembersTable key={`members-${index}`} row={row} />
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    )
};