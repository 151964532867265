import { useState, useRef, useEffect } from 'react';
import { employee } from '../../../api/employee';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';

import { IconButton, TableRow, TableCell, Collapse, Box, Grid, Typography, FormControl, FormLabel, OutlinedInput, Button, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const RowEmployee = ({ row, reloadEmployees, handleDeleteButton, listServices }) => {
    const cookies = new Cookies(null, { path: '/' });
    const [open, setOpen] = useState(false);
    const [employeeService, setEmployeeService] = useState([]);

    useEffect(() => {
        if(row && row.service)
        {
            setEmployeeService(JSON.parse(row.service));
        }
    }, []);

    const nameRef = useRef(null);

    const updateEmployee = async () => {
        const name = nameRef.current.value;
        if(name === undefined || name === '')
        {
            return;
        }

        try {
            const employee_id = row.id;
            const token = cookies.get('access_token');
            const service = new employee();
            const editEmployee = await service.editEmployee(token, employee_id, name, employeeService);
            
            if(editEmployee.data.status_code === 200 && editEmployee.data.result)
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: 'แก้ไขข้อมูลเรียบร้อยแล้วค่ะ',
                    confirmButtonText: 'ตกลง'
                });
                return reloadEmployees();
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleOnChangeChecked = (service_id) => {
        let tmp_employeeService = [
            ...employeeService
        ];

        const findIndexService = tmp_employeeService.findIndex((element) => element === service_id);
        if(findIndexService === -1)
        {
            tmp_employeeService.push(service_id);
        }
        else
        {
            tmp_employeeService = tmp_employeeService.filter((element) => element !== service_id);
        }

        setEmployeeService(tmp_employeeService);
    }

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="center">
                    { row.name }
                </TableCell>
                <TableCell align="center">
                    { row.username }
                </TableCell>
                <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton onClick={() => setOpen(!open)} sx={
                        {
                            backgroundColor: '#154f81',
                            mx: .5,
                            borderRadius: 3,
                            '&:hover': {
                                backgroundColor: '#0c2f4d'
                            }
                        }
                    }>
                        <EditIcon fontSize='small' sx={{ color: 'white' }} />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteButton(row.id)} sx={
                        {
                            backgroundColor: '#f20000',
                            mx: .5,
                            borderRadius: 3,
                            '&:hover': {
                                backgroundColor: '#bf0000'
                            }
                        }
                    }>
                        <DeleteIcon fontSize='small' sx={{ color: 'white' }} />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        แก้ไข { row.username }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                        <FormLabel htmlFor={`edit_employee_username_${row.id}`}>
                                            ชื่อผู้ใช้
                                        </FormLabel>
                                        <OutlinedInput
                                            name={`edit_employee_username_${row.id}`}
                                            type={`text`}
                                            size='small'
                                            fullWidth
                                            autoComplete={`edit_employee_username_${row.id}`}
                                            disabled
                                            defaultValue={row.username}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <FormControl fullWidth>
                                        <FormLabel htmlFor={`edit_employee_name_${row.id}`}>
                                            ชื่อ
                                        </FormLabel>
                                        <OutlinedInput
                                            name={`edit_employee_name_${row.id}`}
                                            type={`text`}
                                            size='small'
                                            fullWidth
                                            autoComplete={`edit_employee_name_${row.id}`}
                                            defaultValue={row.name}
                                            inputRef={nameRef}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        บริการ
                                    </Typography>
                                    <FormGroup>
                                        {
                                            listServices.map((service, index) => {
                                                return (
                                                    <FormControlLabel control={<Checkbox onChange={() => handleOnChangeChecked(service.id)} defaultChecked={employeeService.findIndex((element) => element === service.id) !== -1} />} label={service.name} key={`service-show-${row.username}-${index}`} />
                                                )
                                            })
                                        }
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullWidth onClick={updateEmployee} sx={
                                        {
                                            backgroundColor: `#d3c5b8`,
                                            color: '#4b3320',
                                            '&:hover': {
                                                backgroundColor: `#a0958b`,
                                                color: '#4b3320',
                                            }
                                        }
                                    }>
                                        <EditIcon /> แก้ไข
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )

}

export default RowEmployee;