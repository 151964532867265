import { useEffect, useState, useRef } from "react";
import { employee } from "../../../api/employee";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";

import { Container, Box, Grid, Divider, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';

import Navbar from "../Navbar/Navbar";
import PageLoading from "../../Loading/PageLoading";
import DayData from "./DayData";

const ManageDayBlocked = ({ loaded }) => {
    const cookies = new Cookies(null, { path: 'null' });
    const [blockedDate, setBlockedDate] = useState([]);
    const dateRef = useRef(null);

    useEffect(() => {
        reloadBlockedDay();
    }, []);

    const reloadBlockedDay = async () => {
        try {
            const token = cookies.get('access_token');
            const service = new employee();
            const getBlockedDate = await service.getBlockedDate(token);

            if(getBlockedDate.data.status_code !== 200)
            {
                return;
            }

            setBlockedDate(getBlockedDate.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    const deleteBlockedDate = async (id) => {
        try {
            const token = cookies.get('access_token');
            const service = new employee();
            const deleteBlockedDate = await service.deleteBlockedDate(token, id);
            
            if(deleteBlockedDate.data.status_code === 200 && deleteBlockedDate.data.result)
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: 'ยกเลิกเรียบร้อยแล้วค่ะ',
                    confirmButtonText: 'ตกลง'
                });
            }

            reloadBlockedDay();
        } catch (err) {
            console.log(err);
        }
    }

    const addDateBlocked = async () => {
        const dateValue = dateRef.current.value;

        if(dateValue === undefined || dateValue === '')
        {
            return;
        }

        try {
            const token = cookies.get('access_token');
            const service = new employee();
            const addBlockedDate = await service.addBlockedDate(token, dateValue);

            if(addBlockedDate.data.status_code === 200 && addBlockedDate.data.result)
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `เพิ่มวันที่ ${dateValue} เรียบร้อยแล้วค่ะ`,
                    confirmButtonText: 'ตกลง'
                });
            }

            reloadBlockedDay();
        } catch (err) {
            console.log(err);
        }
    }

    return (
        loaded ? <>
            <Navbar loaded={loaded} />
            <Container sx={{ pt: 12, mb: 3 }}>
                <Box width={`100%`} bgcolor={`white`} borderRadius={3} sx={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.2)" }}>
                    <Box p={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography fontSize={18}>
                                    จัดการวันที่ไม่ต้องการให้จอง
                                </Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                slots={{
                                                    openPickerIcon: CalendarMonthRoundedIcon,
                                                }}
                                                slotProps={{
                                                    openPickerIcon: { fontSize: 'large' },
                                                    openPickerButton: { color: 'black' },
                                                    textField: {
                                                        focused: false,
                                                        color: 'secondary',
                                                    },
                                                }}
                                                sx={
                                                    {
                                                        width: '100%',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: 1,
                                                        },
                                                        '& .MuiIconButton-root': {
                                                            backgroundColor: '#fff',
                                                            color: '#d3c5b8',
                                                            borderRadius: 3
                                                        },
                                                        '& .MuiIconButton-root:hover': {
                                                            backgroundColor: '#fff',
                                                            color: '#a0958b',
                                                            borderRadius: 3
                                                        },
                                                    }
                                                }
                                                inputFormat="dd.MM.yyyy"
                                                format="D/MM/YYYY"
                                                inputRef={dateRef}
                                                disablePast
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button onClick={addDateBlocked} fullWidth sx={
                                            {
                                                backgroundColor: `#d3c5b8`,
                                                color: '#4b3320',
                                                height: `100%`,
                                                '&:hover': {
                                                    backgroundColor: `#a0958b`,
                                                    color: '#4b3320',
                                                }
                                            }
                                        }>
                                            เพิ่มวันที่ไม่ต้องการให้จอง
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">
                                                    วันที่
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                blockedDate.length > 0 ? blockedDate.sort((a, b) => new Date(a.date) - new Date(b.date)).map((data, index) => <DayData data={data} index={index} deleteBlockedDate={deleteBlockedDate} key={`blocked-date-${index}`} />) : <TableRow>
                                                    <TableCell colSpan={2} align="center">
                                                        ยังไม่มีวันที่ถูกไม่ให้จอง ณ ขณะนี้
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </> : <PageLoading />
    )
}

export default ManageDayBlocked;