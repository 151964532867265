import { Box, Typography } from "@mui/material"
import { Link } from "react-router-dom"

const MenuButton = ({ title, icon, link }) => {
    return (
        <>
            {
                link !== undefined ? <Link to={link}>
                    <Box bgcolor={`#d3c5b8`} borderRadius={2} py={1}>
                        <Box display={`flex`} justifyContent={`center`} mb={.5}>
                            { icon }
                        </Box>
                        <Typography display={`flex`} justifyContent={`center`} fontSize={`small`} color={`#4b3320`}>
                            {title}
                        </Typography>
                    </Box>
                </Link> : <Box bgcolor={`#d3c5b8`} borderRadius={2} py={1}>
                    <Box display={`flex`} justifyContent={`center`} mb={.5}>
                        { icon }
                    </Box>
                    <Typography display={`flex`} justifyContent={`center`} fontSize={`small`} color={`#4b3320`}>
                        {title}
                    </Typography>
                </Box>
            }
            
        </>
    )
}

export default MenuButton