import { useEffect, useState } from "react";
import { employee } from "../../../api/employee";
import { api } from "../../../api/reservation";
import { default as Fuse } from 'fuse.js';
import Cookies from "universal-cookie";

import { Container, Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, FormControl, FormLabel, OutlinedInput, Select, MenuItem } from "@mui/material";

import Navbar from "../Navbar/Navbar";
import PageLoading from "./../../Loading/PageLoading";
import ExportToCsv from "./ExportToCsv";

const Home = ({ loaded, employees }) => {
    const cookies = new Cookies(null, { path: '/' });
    const [historyReservation, setHistoryReservation] = useState([]);
    const [defaultHistoryReservation, setDefaultHistoryReservation] = useState([]);
    const [listServices, setListServices] = useState([]);

    useEffect(() => {
        reloadService();
        reloadHistoryReservation();
    }, []);

    const reloadService = async () => {
        try {
            const service = new api();
            const getListServices = await service.getListServices();

            if(getListServices.data.status_code === 200)
            {
                setListServices(getListServices.data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const reloadHistoryReservation = async () => {
        try {
            const token = cookies.get('access_token');
            const service = new employee();
            const getHistoryReservation = await service.getReservation(token);

            if(getHistoryReservation.data.status_code === 200)
            {
                setHistoryReservation(getHistoryReservation.data.history);
                setDefaultHistoryReservation(getHistoryReservation.data.history);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const fuseResults = (keyword) => {
        const fuse = new Fuse(defaultHistoryReservation, { keys: ['name', 'phone', 'service_name', 'employee_name'] });
        return fuse.search(keyword, {}).map((result) => ({ ...result.item }))
    }

    const handleChangeSearchValue = (e) => {
        if(e.target.value === undefined || e.target.value === '')
        {
            return setHistoryReservation(defaultHistoryReservation);
        }

        const result = fuseResults(e.target.value);
        setHistoryReservation(result);
    }

    const handleChangeService = async (e, reservation_id) => {
        try {
            const token = cookies.get('access_token');
            const service_id = e.target.value;
            const service = new employee();
            await service.updateReservationServiceId(token, reservation_id, service_id);
        } catch (err) {
            console.log(err);
        }
    }

    const handleChangeEmployee = async (e, reservation_id) => {
        try {
            const token = cookies.get('access_token');
            const employee_id = e.target.value;
            const service = new employee();
            await service.updateReservationEmployeeId(token, reservation_id, employee_id);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        loaded ? <>
            <Navbar loaded={loaded} />
            <Container sx={{ pt: 12, mb: 3 }}>
                <Box width={`100%`} bgcolor={`white`} borderRadius={3} sx={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.2)" }}>
                    <Box p={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {
                                    historyReservation.length > 0 && listServices.length > 0 && <ExportToCsv data={historyReservation} />
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <Box height={`100%`}>
                                    <Typography fontSize={18} display={`flex`} alignItems={`center`} height={`100%`}>
                                        รายการจอง (ล่าสุด - เก่าสุด)
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box width={`100%`} display={`flex`} justifyContent={`flex-end`}>
                                    <FormControl>
                                        <FormLabel htmlFor="search_history_reservation_data">
                                            ค้นหา
                                        </FormLabel>
                                        <OutlinedInput
                                            name='search_history_reservation_data'
                                            type={`text`}
                                            size='small'
                                            autoComplete="search_history_reservation_data"
                                            onChange={handleChangeSearchValue}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">ชื่อผู้จอง</TableCell>
                                                <TableCell align="center">เบอร์โทรศัพท์</TableCell>
                                                <TableCell align="center">วันเวลา</TableCell>
                                                <TableCell align="center">บริการ</TableCell>
                                                <TableCell align="center">พนักงาน</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                historyReservation.length > 0 && listServices.length > 0 ? historyReservation.sort((a, b) => new Date(b.date_time) - new Date(a.date_time)).map((row, index) => {
                                                    const date = new Date(row.date);
                                                    const reservation_date = `${(String(date.getDate())).length > 1 ? date.getDate() : `0${date.getDate()}`}/${(String(date.getMonth() + 1)).length > 1 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}/${date.getFullYear()}`;
                                                    const timeString = row.time;
                                                    const timeParts = timeString.split(":");
                                                    const formattedTime = `${timeParts[0]}:${timeParts[1]}`;
                                                    // console.log(listServices)
                                                    const service_index = listServices.findIndex((element) => element.name === row.service_name);
                                                    
                                                    return (
                                                        <TableRow
                                                            key={index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="center">
                                                                { row.name }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                { row.phone }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                { reservation_date } { formattedTime }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Select
                                                                    fullWidth
                                                                    size="small"
                                                                    onChange={(e) => handleChangeService(e, row.id)}
                                                                    defaultValue={`${listServices[service_index].id}`}
                                                                >
                                                                    {
                                                                        listServices.map((item, index) => {
                                                                            return (
                                                                                <MenuItem value={`${item.id}`} key={index}>{item.name}</MenuItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </Select>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Select
                                                                    fullWidth
                                                                    size="small"
                                                                    onChange={(e) => handleChangeEmployee(e, row.id)}
                                                                    defaultValue={`${row.employee_name === null ? '0' : row.employee_id}`}
                                                                >
                                                                    <MenuItem value={`0`}>ยังไม่มีพนักงาน</MenuItem>
                                                                    {
                                                                        employees.map((item, index) => {
                                                                            return (
                                                                                <MenuItem value={`${item.id}`} key={index}>{item.name}</MenuItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </Select>
                                                                {/* { row.employee_name === null ? 'ไม่มีพนักงาน' : row.employee_name } */}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }) : <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell colSpan={5} align="center">
                                                        ไม่มีประวัติการจอง
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </> : <PageLoading />
    )
}

export default Home;