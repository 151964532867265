import { Container, Paper, Divider, Typography, Box, Stepper, Step, StepLabel } from "@mui/material"
import { useState } from "react";
import PageLoading from "../Loading/PageLoading";

// COMPONENTS
import ChooseService from "./ChooseService";
import ChooseDate from "./ChooseDate";
import ChooseTime from "./ChooseTime";
import InputForm from "./InputForm";
import Confirm from "./Confirm";
import Success from "./Success";

const steps = [
    'เลือกบริการ',
    'เลือกวันที่จอง',
    'เลือกเวลาที่จอง',
    'กรอกข้อมูล',
    'ยืนยันข้อมูล',
    'เสร็จสิ้น'
];

const Reservation = (props) => {
    const [stepCount, setStepCount] = useState(0);
    const [selectedServiceName, setSelectedServiceName] = useState(null);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [nameInput, setNameInput] = useState(null);
    const [phoneInput, setPhoneInput] = useState(null);

    return (
        props.loaded ? <Container sx={
            {
                py: 3
            }
        }>
            <Paper sx={{ p: 2 }}>
                <Typography fontSize={`large`} display={`flex`} justifyContent={`center`}>
                    นัดล่วงหน้า
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Box>
                    <Stepper activeStep={stepCount} alternativeLabel>
                        {
                            steps.map((label, index) => (
                                <Step key={`${label}-${index}`} sx={{
                                    '& .MuiStepLabel-root .Mui-completed': {
                                        color: '#d3c5b8', // circle color (COMPLETED)
                                    },
                                    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                                        color: '#4b3320', // Just text label (COMPLETED)
                                    },
                                    '& .MuiStepLabel-root .Mui-active': {
                                        color: '#d3c5b8', // circle color (ACTIVE)
                                    },
                                    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                                        color: '#4b3320', // Just text label (ACTIVE)
                                    },
                                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                        fill: '#4b3320', // circle's number (ACTIVE)
                                    },
                                }}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))
                        }
                    </Stepper>
                </Box>
                <Box>
                    {
                        stepCount === 0 && <ChooseService setStepCount={setStepCount} selectedService={selectedService} setSelectedService={setSelectedService} setSelectedServiceName={setSelectedServiceName} />
                    }
                    {
                        stepCount === 1 && <ChooseDate selectedDate={selectedDate} setSelectedDate={setSelectedDate} setStepCount={setStepCount} />
                    }
                    {
                        stepCount === 2 && <ChooseTime selectedTime={selectedTime} setSelectedTime={setSelectedTime} selectedDate={selectedDate} setStepCount={setStepCount} selectedService={selectedService} />
                    }
                    {
                        stepCount === 3 && <InputForm nameInput={nameInput} phoneInput={phoneInput} setNameInput={setNameInput} setPhoneInput={setPhoneInput} selectedTime={selectedTime} selectedDate={selectedDate} setStepCount={setStepCount} selectedService={selectedService} selectedServiceName={selectedServiceName} />
                    }
                    {
                        stepCount === 4 && <Confirm nameInput={nameInput} phoneInput={phoneInput} selectedTime={selectedTime} selectedDate={selectedDate} setStepCount={setStepCount} setSelectedDate={setSelectedDate} setSelectedTime={setSelectedTime} profile={props.profile} selectedService={selectedService} selectedServiceName={selectedServiceName} />
                    }
                    {
                        stepCount === 5 && <Success nameInput={nameInput} phoneInput={phoneInput} selectedTime={selectedTime} selectedDate={selectedDate} />
                    }
                </Box>
            </Paper>
        </Container> : <PageLoading />
    )
}

export default Reservation;