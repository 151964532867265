import { useState, useRef } from "react";

import { TableRow, TableCell, IconButton, Collapse, Box, Typography, Grid, OutlinedInput, FormControl, FormLabel, Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const TableRowManageService = ({ item, editService, deleteService }) => {
    const [open, setOpen] = useState(false);
    const nameRef = useRef(null);
    const priceRef = useRef(null);

    return (
        <>
            <TableRow>
                <TableCell align="center">
                    {
                        item.name
                    }
                </TableCell>
                <TableCell align="center">
                    {
                        parseFloat(item.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    }
                </TableCell>
                <TableCell align="center">
                    <IconButton onClick={() => setOpen(!open)} sx={
                        {
                            backgroundColor: '#154f81',
                            mx: .5,
                            borderRadius: 3,
                            '&:hover': {
                                backgroundColor: '#0c2f4d'
                            }
                        }
                    }>
                        <EditIcon fontSize='small' sx={{ color: 'white' }} />
                    </IconButton>
                    <IconButton onClick={() => deleteService(item.id)} sx={
                        {
                            backgroundColor: '#f20000',
                            mx: .5,
                            borderRadius: 3,
                            '&:hover': {
                                backgroundColor: '#bf0000'
                            }
                        }
                    }>
                        <DeleteIcon fontSize='small' sx={{ color: 'white' }} />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom component="div">
                                        แก้ไขบริการ { item.name }
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel htmlFor={`edit_service_name_${item.id}`}>
                                            ชื่อบริการ
                                        </FormLabel>
                                        <OutlinedInput
                                            name={`edit_service_name_${item.id}`}
                                            type={`text`}
                                            size='small'
                                            fullWidth
                                            autoComplete={`edit_service_name_${item.id}`}
                                            defaultValue={item.name}
                                            inputRef={nameRef}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel htmlFor={`edit_service_price_${item.id}`}>
                                            ราคา
                                        </FormLabel>
                                        <OutlinedInput
                                            name={`edit_service_price_${item.id}`}
                                            type={`text`}
                                            size='small'
                                            fullWidth
                                            autoComplete={`edit_service_price_${item.id}`}
                                            defaultValue={item.price}
                                            inputRef={priceRef}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullWidth onClick={() => { editService(nameRef.current.value, priceRef.current.value, item.id, setOpen) }} sx={
                                        {
                                            backgroundColor: `#d3c5b8`,
                                            color: '#4b3320',
                                            '&:hover': {
                                                backgroundColor: `#a0958b`,
                                                color: '#4b3320',
                                            }
                                        }
                                    }>
                                        <EditIcon /> แก้ไข
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default TableRowManageService;