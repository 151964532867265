import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../api/reservation";

import { Container, Paper, Divider, Typography, Box, Button } from "@mui/material"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// COMPONENTS
import PageLoading from "../Loading/PageLoading";

// MUI ICONS
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const HistoryPoints = ({ loaded, profile }) => {
    const navigate = useNavigate();
    const [processing, setProccessing] = useState(true);
    const [dataHistoryPoints, setDataHistoryPoints] = useState([]);

    useEffect(() => {
        reloadHistoryPoints();
    }, [profile]);

    const reloadHistoryPoints = async () => {
        try {
            const service = new api();
            const getHistory = await service.getHistoryPoints(profile.userId);

            if(getHistory.data.history !== undefined)
            {
                setDataHistoryPoints(getHistory.data.history);
            }

            setProccessing(false);
        } catch (err) {
            console.log(err);
        }
    }

    const backToHome = () => {
        navigate('/');
    }

    return (
        loaded ? <Container sx={
            {
                py: 3
            }
        }>
            <Paper sx={{ p: 2 }}>
                <Typography fontSize={`large`} display={`flex`} justifyContent={`center`}>
                    ประวัติการสะสมคะแนน
                </Typography>
                <Divider sx={{ mb: 2 }} />
                {
                    processing ? <PageLoading /> : <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">รายละเอียด</TableCell>
                                    <TableCell align="center">หมายเหตุ</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    dataHistoryPoints.length > 0 ? dataHistoryPoints.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((row, index) => {
                                        return (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">
                                                    { row.detail }
                                                </TableCell>
                                                <TableCell align="center">
                                                    { row.reason === "" || row.reason === null ? "ไม่มี" : row.reason }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }) : <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell colSpan={4} align="center">
                                            ไม่มีประวัติการสะสมคะแนน
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                <Box width={`100%`} mt={2}>
                    <Button fullWidth onClick={backToHome} sx={
                        {
                            backgroundColor: `#d3c5b8`,
                            color: '#4b3320',
                            '&:hover': {
                                backgroundColor: `#a0958b`,
                                color: '#4b3320',
                            }
                        }
                    }>
                        <ChevronLeftIcon /> กลับหน้าหลัก
                    </Button>
                </Box>
            </Paper>
        </Container> : <PageLoading />
    )
}

export default HistoryPoints;