import { useRef } from "react";
import { Grid, OutlinedInput, FormLabel, FormControl, Button } from "@mui/material";
import Swal from "sweetalert2";

// MUI ICONS
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const InputForm = ({ nameInput, phoneInput, setNameInput, setPhoneInput, selectedTime, selectedDate, setStepCount, selectedService, selectedServiceName }) => {
    const nameInputRef = useRef(null);
    const phoneInputRef = useRef(null);

    const isNumericString = (inputString) => {
        return /^\d+$/.test(inputString);
      }

    const handleNextStep = () => {
        const name = nameInputRef.current.value;
        const phone = phoneInputRef.current.value;
        
        if(name === undefined || name === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'กรุณากรอกชื่อผู้จองด้วยค่ะ',
                confirmButtonText: 'ตกลง'
            });
        }

        if(phone === undefined || phone === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'กรุณากรอกเบอร์โทรศัพท์มือถือด้วยค่ะ',
                confirmButtonText: 'ตกลง'
            });
        }

        if(!isNumericString(phone))
        {
            return Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'กรุณากรอกเบอร์โทรศัพท์มือถือ ให้มีเฉพาะตัวเลขเท่านั้นด้วยค่ะ',
                confirmButtonText: 'ตกลง'
            });
        }

        setNameInput(name);
        setPhoneInput(phone);
        setStepCount(4);
    }

    const handleBackStep = () => {
        setStepCount(2);
    }

    return (
        <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                    <FormLabel htmlFor="reservation_name_input">
                        ชื่อผู้จอง
                    </FormLabel>
                    <OutlinedInput
                        name='reservation_name_input'
                        type={`text`}
                        size='small'
                        fullWidth
                        autoComplete="reservation_name_input"
                        inputRef={nameInputRef}
                        defaultValue={nameInput}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                    <FormLabel htmlFor="reservation_phone_input">
                        เบอร์โทรศัพท์มือถือ
                    </FormLabel>
                    <OutlinedInput
                        name='reservation_phone_input'
                        type={`text`}
                        size='small'
                        fullWidth
                        autoComplete="reservation_phone_input"
                        inputRef={phoneInputRef}
                        defaultValue={phoneInput}
                        inputProps={{ maxLength: 12 }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth>
                    <FormLabel htmlFor="reservation_date_input">
                        บริการ
                    </FormLabel>
                    <OutlinedInput
                        name='reservation_service_input'
                        type={`text`}
                        size='small'
                        fullWidth
                        autoComplete="reservation_service_input"
                        disabled
                        value={selectedServiceName}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                    <FormLabel htmlFor="reservation_date_input">
                        วันที่จอง
                    </FormLabel>
                    <OutlinedInput
                        name='reservation_date_input'
                        type={`text`}
                        size='small'
                        fullWidth
                        autoComplete="reservation_date_input"
                        disabled
                        value={selectedDate}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <FormControl fullWidth>
                    <FormLabel htmlFor="reservation_time_input">
                        เวลาที่จอง
                    </FormLabel>
                    <OutlinedInput
                        name='reservation_time_input'
                        type={`text`}
                        size='small'
                        fullWidth
                        autoComplete="reservation_time_input"
                        disabled
                        value={selectedTime}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button fullWidth onClick={handleNextStep} sx={
                    {
                        backgroundColor: `#d3c5b8`,
                        color: '#4b3320',
                        '&:hover': {
                            backgroundColor: `#a0958b`,
                            color: '#4b3320',
                        },
                        '&:disabled': {
                            color: 'rgba(255, 255, 255, .5)',
                        }
                    }
                }>
                    ถัดไป <NavigateNextIcon />
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button fullWidth onClick={handleBackStep} sx={
                    {
                        backgroundColor: `#d3c5b8`,
                        color: '#4b3320',
                        '&:hover': {
                            backgroundColor: `#a0958b`,
                            color: '#4b3320',
                        }
                    }
                }>
                    <ChevronLeftIcon /> ย้อนกลับ
                </Button>
            </Grid>
        </Grid>
    )
}

export default InputForm;