import { useRef } from "react";

import { TableRow, TableCell, IconButton, OutlinedInput } from "@mui/material";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import DeleteIcon from '@mui/icons-material/Delete';

const AvaliableTime = ({ item, row, dataIndex, deleteTime, updateTime }) => {
    const timeRef = useRef(null);
    const maxRef = useRef(null);

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="center">
                <OutlinedInput
                    name={`reservation_time_${item.id}_${dataIndex}`}
                    type={`text`}
                    size='small'
                    autoComplete={`reservation_time_${item.id}_${dataIndex}`}
                    defaultValue={row.time}
                    inputRef={timeRef}
                />
            </TableCell>
            <TableCell align="center">
                <OutlinedInput
                    name={`reservation_max_${item.id}_${dataIndex}`}
                    type={`text`}
                    size='small'
                    autoComplete={`reservation_max_${item.id}_${dataIndex}`}
                    defaultValue={row.max}
                    inputRef={maxRef}
                />
            </TableCell>
            <TableCell align="center">
                <IconButton onClick={() => updateTime(item.id, row.time, timeRef.current.value, maxRef.current.value)} sx={
                    {
                        backgroundColor: '#d3c5b8',
                        m: .5,
                        borderRadius: 3,
                        '&:hover': {
                            backgroundColor: '#a0958b'
                        }
                    }
                }>
                    <SaveAsIcon fontSize='small' sx={{ color: 'white' }} />
                </IconButton>
                <IconButton onClick={() => deleteTime(item.id, row.time)} sx={
                    {
                        backgroundColor: '#f20000',
                        m: .5,
                        borderRadius: 3,
                        '&:hover': {
                            backgroundColor: '#bf0000'
                        }
                    }
                }>
                    <DeleteIcon fontSize='small' sx={{ color: 'white' }} />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}

export default AvaliableTime;